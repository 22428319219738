<template>
    <div class="max-w-lg mx-auto p-4 rounded-lg shadow-lg">
        <form @submit.prevent="submitForm" class="space-y-4">
            <hr class="border-white">

            <div class="form-group">
                <label class="block text-white required" for="email">Email:</label>
                <input type="email" id="email" v-model="email" required
                    class="w-full mt-1 p-2 bg-white text-gray-800 border border-gray-300 rounded">
            </div>

            <div class="form-group">
                <label class="block text-white required" for="date">Date:</label>
                <input type="text" id="date" v-model="date" required
                    class="w-full mt-1 p-2 bg-white text-gray-800 border border-gray-300 rounded">
            </div>

            <div class="form-group">
                <label class="block text-white" for="location">Location:</label>
                <input type="text" id="location" v-model="location"
                    class="w-full mt-1 p-2 bg-white text-gray-800 border border-gray-300 rounded">
            </div>

            <div class="form-group">
                <label class="block text-white" for="duration">Duration:</label>
                <input type="text" id="duration" v-model="duration"
                    class="w-full mt-1 p-2 bg-white text-gray-800 border border-gray-300 rounded">
            </div>

            <div class="form-group">
                <label class="block text-white required" for="message">Message:</label>
                <textarea id="message" v-model="message" rows="5" required
                    class=" required w-full mt-1 p-2 bg-white resize-none text-gray-800 border border-gray-300 rounded"></textarea>
            </div>

            <div class="form-group">
                <label class="block text-white required" for="agree">Hiermit bestätige ich, dass ich die
                    <a @click.prevent="goToImpressum" class="text-red-500 underline">Datenschutzvereinbarungen</a>
                    gelesen habe und mit ihnen einverstanden bin
                </label>
                <input type="checkbox" id="agree" v-model="agreed" class="mt-2 mx-auto block">
            </div>

            <hr class="border-white">

            <button type="submit"
                class="w-full py-2 mt-4 bg-white text-gray-800 font-bold rounded hover:bg-gray-200">Submit</button>
        </form>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'ContactForm',
    data() {
        return {
            email: '',
            date: '',
            location: '',
            duration: '',
            message: '',
            agreed: false,
        };
    },
    methods: {
        goToImpressum() {
            this.$router.push('/impressum');
        },
        submitForm() {
            if (!this.agreed) {
                alert("Sie müssen den Nutzungsbedingungen zustimmen, um fortzufahren.");
                return;
            }

            const formData = new FormData();
            formData.append('email', this.email);
            formData.append('date', this.date);
            formData.append('location', this.location);
            formData.append('duration', this.duration);
            formData.append('message', this.message);

            axios.post('/buzzerbeater/mail.php', formData)
                .then(response => {
                    alert(response.data === 'Success' ? 'Email sent successfully' : 'There was an error sending the email');
                })
                .catch(error => {
                    console.error('Error sending email:', error);
                    alert('There was an error sending the email');
                });
        }
    }
};
</script>

<style scoped>
.required::after {
    content: "*";
    color: red;
    margin-left: 5px;
}
</style>