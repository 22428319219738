<template>
    <button @click="toImpressum" class="button">{{ buttonText }}</button>
</template>

<script>
export default {
    props: {
        buttonText: {
            type: String,
            required: true
        }
    },
    methods: {
        toImpressum() {

            this.$router.push('/impressum');
        }
    }
}
</script>

<style scoped></style>
