import HomeView from '../views/HomeView.vue';
import LoadingScreen from '../components/LoadingScreen.vue';
import ImpressumView from '../views/ImpressumView.vue';

import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    { path: '/', component: LoadingScreen },
    { path: '/home', component: HomeView },
    { path: '/impressum', component: ImpressumView },
];

// Create the router instance
const router = createRouter({
    history: createWebHistory(),
    routes,
});

// Navigation guard to redirect to '/home' after '/loading'
router.beforeEach((to, from, next) => {
    if (to.path === '/' && from.path === '/loading') {
        next('/home');
    } else {
        next();
    }
});

export default router;
