<template>
    <div class="loading-screen" v-if="isLoading">
  
      <div class="spinner"></div>
  
      <div class="loading-logo">
  
        <svg class="buzzer" id="Ebene_3" data-name="Ebene 3" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 997 433">
  
          <g id="Buzzerbeater_Logo">
            <g id="B">
              <path class="cls-1"
                d="M80.62,369.95c-7.13,0-15.44-1.77-24.45-6.97-8.66-5-13.53-11.4-14.49-19-1.72-13.76,10.49-25.85,11.01-26.35l5.59,5.73-2.79-2.86,2.8,2.86c-.1.1-9.91,9.87-8.66,19.67.63,4.98,4.18,9.36,10.55,13.04,15.64,9.04,28.48,6.1,36.5,2.05,3.39-1.71,5.33-3.38,5.35-3.39l5.27,6.02c-.27.23-2.72,2.34-7.01,4.51-4.35,2.2-11.17,4.71-19.66,4.71Z" />
              <path class="cls-1"
                d="M115.97,368.2c-6.08,0-12.76-.81-18.71-2.72l2.44-7.62c10.85,3.47,23.91,2.57,28.79.62,4.66-1.87,17.05-6.82,32.85-27.79,13.74-18.24-.25-33-.85-33.62l5.7-5.61c6.82,6.9,15.91,24.98,1.54,44.05-17.14,22.75-31.04,28.31-36.27,30.4-3.57,1.43-9.21,2.29-15.49,2.29Z" />
              <path class="cls-1"
                d="M159.21,304l-4.86-6.35c18.07-13.81,15.28-35.27,15.15-36.18l-.09-.61.1-.62c.07-.55,1.74-14.73-22.94-22.96-25.67-8.56-44.34,11.8-45.12,12.67l-5.97-5.32c.92-1.03,22.88-25.19,53.62-14.94,28.66,9.55,28.71,27.4,28.38,31.17.45,4,2.12,27.53-18.28,43.13Z" />
              <path class="cls-1"
                d="M103.09,240.56c-1.57-12.72-18.66-12.04-19.39-12.01l-.42-7.99c8.73-.47,25.76,2.91,27.75,19.02l-7.94.98Z" />
              <path class="cls-1"
                d="M147.1,234.27l-6.98-3.92c6.36-11.32,8.5-20.9,6.2-27.7-1.93-5.71-6.38-7.68-6.57-7.76l3.03-7.4c.32.13,7.83,3.24,11.03,12.34,3.23,9.17.96,20.76-6.72,34.44Z" />
              <path class="cls-1"
                d="M150.27,198.62l-6.06-5.22c19.6-22.74,8-40.31,7.5-41.04-18.48-27.98-49.81-9.19-51.13-8.37l-4.21-6.81c.39-.24,9.78-5.99,22-7.65,11.65-1.59,28.04.3,39.96,18.35h0c1.07,1.63,14.96,24.03-8.06,50.75Z" />
              <path class="cls-1"
                d="M63.02,152.25l-6.72-4.34c.49-.77,12.29-18.81,30.36-22.33,9.04-1.76,17.96.39,26.49,6.41l-4.61,6.54c-6.68-4.7-13.52-6.42-20.32-5.1-14.7,2.84-25.1,18.66-25.2,18.82Z" />
              <path class="cls-1"
                d="M45.53,203.51c-.58-.06-14.29-1.67-21.21-12.52-4.61-7.23-5.03-16.56-1.25-27.73l.14-.4.22-.36c.16-.27,16.5-26.86,47.97-13.1l-3.21,7.33c-23.46-10.26-35.89,6.81-37.69,9.55-2.81,8.52-2.63,15.38.54,20.37,4.82,7.61,15.26,8.9,15.37,8.91l-.88,7.95Z" />
              <path class="cls-1"
                d="M52.35,253.26c-.3-.25-29.88-25.34-6.17-61.87l6.71,4.36c-19.77,30.45,3.56,50.51,4.56,51.35l-5.1,6.16Z" />
              <path class="cls-1"
                d="M59.42,331.25c-.4-.35-9.87-8.73-16.05-23.6-5.7-13.72-9.57-35.78,4.38-62.95l7.12,3.65c-23.84,46.46,8.44,75.66,9.82,76.88l-5.27,6.02Z" />
              <path class="cls-1"
                d="M97.35,292.08l-2.69-7.53-.11.04c.07-.03,6.57-2.95,10.08-17.47l7.78,1.88c-4.55,18.84-13.99,22.71-15.06,23.09Z" />
              <path class="cls-1"
                d="M122.72,288.09c-.43,0-.85-.02-1.29-.05-6.71-.49-12.65-4.64-17.68-12.34l6.7-4.37c3.58,5.48,7.45,8.42,11.52,8.73,6.49.5,12.11-5.66,12.16-5.72l5.99,5.31c-.32.36-7.57,8.45-17.4,8.45Z" />
              <path class="cls-1"
                d="M131.67,312.91c-6.04-4.74-14.42-17.9-2.92-33.79l6.48,4.69c-9.64,13.33.24,21.89,1.4,22.83l-4.96,6.28Z" />
              <path class="cls-1"
                d="M115.71,326.38l-7.22-3.44c6.84-14.36,30.19-17.05,31.19-17.15l.86,7.95s0,0,0,0h0c-5.52.61-20.74,4.07-24.82,12.64Z" />
              <path class="cls-1"
                d="M114.64,338.61l-7.47-2.87c.2-.53,4.93-13.56-4.13-24.63-8.07-9.86-17.46-4.08-18.5-3.39l-4.49-6.62c5.58-3.81,18.86-7.67,29.18,4.94,12.06,14.74,5.68,31.85,5.4,32.57Z" />
              <path class="cls-1"
                d="M94.51,305.59l-5.35-5.95c3.12-2.81,2.04-9.94,1.38-12.59-1.11-1.48-2.18-1.79-2.92-1.88-3.31-.44-8.01,2.73-9.78,4.33l-5.37-5.93c.83-.76,8.39-7.34,16.19-6.33,3.63.48,6.72,2.53,8.92,5.93l.31.48.16.55c.44,1.47,4.08,14.54-3.54,21.4Z" />
              <path class="cls-1"
                d="M87.46,199.31l-6.76-4.28c.38-.61,9.71-15.01,35.67-11.46,1.39.19,2.82.43,4.26.72l-1.57,7.84c-1.28-.26-2.54-.47-3.77-.64-20.56-2.81-27.54,7.38-27.83,7.81Z" />
              <path class="cls-1"
                d="M114.25,191.21c-.75-.32-7.36-3.35-8.01-9.99-.54-5.53,3.17-10.94,11.34-16.57l4.53,6.59c-7.64,5.25-7.99,8.35-7.91,9.17.15,1.69,2.45,3.09,3.24,3.45l-3.19,7.34Z" />
              <path class="cls-1"
                d="M95.89,192.87l-7.24-3.41c3.34-7.08,3.35-10.91,2.24-12.15-1.95-2.18-9.29-1.17-13.84.39l-2.61-7.56c2.65-.92,16.16-5.13,22.4,1.83,3.93,4.37,3.61,11.21-.96,20.91Z" />
              <path class="cls-1"
                d="M99.12,180.98c-2.34,0-4.53-.42-6.29-1.5l4.18-6.82c2.07,1.27,10.19-.88,16.07-3.65l3.41,7.24c-2.84,1.34-10.71,4.74-17.36,4.74Z" />
            </g>
            <g id="U">
              <path class="cls-1"
                d="M137.36,134.94l-7.81-1.73c2.2-9.9,6.9-17.09,13.96-21.34,11.35-6.83,24.51-3.33,25.06-3.18l-2.11,7.72c-.1-.03-10.51-2.73-18.88,2.35-5.09,3.09-8.53,8.53-10.22,16.19Z" />
              <path class="cls-1"
                d="M192.61,155.04l-6.12-5.15,3.06,2.58-3.06-2.57c.17-.21,17.03-20.68,6.82-37.02-5.86-9.38-14.33-9.37-18.86-8.52-6.4,1.19-11.3,5.14-11.86,8.07l-7.86-1.51c1.27-6.62,8.94-12.69,18.25-14.43,11.11-2.07,20.99,2.35,27.11,12.15,13.31,21.29-6.63,45.39-7.48,46.41Z" />
              <path class="cls-1"
                d="M196.53,209.98l-6.81-4.2,3.4,2.1-3.41-2.09c.82-1.34,19.71-33.07-9.78-56.13l4.93-6.3c35.18,27.51,11.91,66.23,11.67,66.62Z" />
              <path class="cls-1"
                d="M198.46,261.82l-5.9-5.4,2.95,2.7-2.96-2.69c.09-.1,8.96-10.09,7.88-23.29-.78-9.51-6.48-18.56-16.95-26.9l4.98-6.26c12.3,9.8,19.01,20.77,19.94,32.62,1.31,16.7-9.49,28.72-9.95,29.22Z" />
              <path class="cls-1"
                d="M197.96,286.4l-5.41-5.9,2.7,2.95-2.72-2.94c1.17-1.09,11.14-10.99,2.69-23.22l6.58-4.55c10.07,14.58,1.88,28.39-3.86,33.65Z" />
              <path class="cls-1"
                d="M207.01,312.26c0-.17-.6-17.05-9.57-23.55-3.85-2.8-8.76-3.26-15-1.43l-2.26-7.67c8.67-2.55,16.06-1.66,21.98,2.64,12.14,8.84,12.82,28.93,12.84,29.79l-8,.21,4-.11-4,.11Z" />
              <path class="cls-1"
                d="M209.09,291.66l-7.97-.72c1.06-11.66,4.5-19.06,10.22-22,.17-.09.35-.17.52-.25-3.77-4.79-5.99-10.06-6.64-15.77-1.65-14.47,7.63-26.2,8.03-26.69l6.23,5.02-3.12-2.51,3.12,2.5c-.08.1-7.61,9.72-6.31,20.84.76,6.54,4.47,12.46,11.02,17.61l-4.5,6.59c-.06-.03-2.54-1.37-4.76-.2-2.97,1.57-5.1,7.25-5.86,15.58Z" />
              <path class="cls-1"
                d="M215.12,233.83c-12.96-13.99-4.39-29.99,1.96-36.5l5.73,5.59-2.86-2.79,2.87,2.78c-1.33,1.38-12.65,13.8-1.83,25.48l-5.87,5.44Z" />
              <path class="cls-1"
                d="M212.85,207.47c-18.35-27.04-8.97-46.55-1.74-55.77,2.95-3.76,5.51-5.77,5.8-5.99l4.87,6.34-2.44-3.17,2.45,3.16s-2.06,1.62-4.39,4.59c-5.88,7.5-13.44,23.5,2.06,46.34l-6.62,4.49Z" />
              <path class="cls-1"
                d="M250.04,207l-7.17-3.55c6.84-13.79,5.06-18.72,3.99-20.2-2.07-2.84-7.48-2.12-7.53-2.11l-12.63,1.9,9.27-8.76c8.83-8.33,11.35-28.06,11.56-29.85.41-12.88-2.86-21.35-9.73-25.19-4.41-2.47-9.95-2.57-14.82-.27-5.66,2.67-9.05,6.26-10.36,10.96-2.34,8.41,2.8,18.76,4.97,22.03l-6.66,4.43c-.4-.61-9.81-14.99-6.02-28.61,1.94-6.98,6.87-12.38,14.65-16.05,7.21-3.4,15.48-3.21,22.14.52,6.68,3.74,14.5,12.43,13.81,32.6l-.02.28c-.09.82-1.88,17.09-9.05,28.7,2.45.74,4.97,2.12,6.84,4.65,4.38,5.94,3.32,15.27-3.24,28.52Z" />
              <path class="cls-1"
                d="M245.27,293.07l-7.17-3.55c13.15-26.53,17.24-50.08,11.82-68.1-4.02-13.37-11.95-19.15-12.03-19.21l4.59-6.56c.4.28,9.97,7.07,14.9,22.79,6.4,20.39,2.32,45.5-12.11,74.62Z" />
              <path class="cls-1"
                d="M228.76,353.92l-3.33-7.28,1.66,3.64-1.67-3.63c.16-.07,15.6-7.37,20.68-22.86,3.41-10.38,1.5-22.25-5.66-35.28l7.01-3.85c8.3,15.09,10.39,29.13,6.22,41.72-6.23,18.81-24.16,27.2-24.92,27.54Z" />
              <path class="cls-1"
                d="M207.36,367c-8.15,0-17.3-3.72-27.33-11.12l4.75-6.44c11.13,8.21,20.49,11.15,27.81,8.74,9.07-2.99,12.43-13.62,12.46-13.73l7.66,2.31c-.18.6-4.52,14.65-17.53,18.99-2.49.83-5.1,1.25-7.82,1.25Z" />
              <path class="cls-1"
                d="M177.05,357.01c-2.55,0-5.32-.37-8.1-1.37-6.01-2.18-10.66-6.78-13.84-13.68l7.27-3.34c2.24,4.86,5.35,8.05,9.24,9.48,6.69,2.46,13.88-.81,13.95-.84l3.41,7.23c-.29.14-5.42,2.52-11.94,2.52Z" />
            </g>
            <g id="Z">
              <path class="cls-1"
                d="M228.99,115.35c-.61-1.12-5.85-11.16-2.29-19.18,1.21-2.72,3.96-6.29,10.19-7.78,16.73-4.02,23.45,2.81,24.17,3.6l-5.95,5.35.09.1s-4.46-4.14-16.44-1.26c-3.68.88-4.48,2.66-4.74,3.24-1.67,3.75.95,10.17,2.01,12.12l-7.03,3.82Z" />
              <path class="cls-1"
                d="M259.61,100.94l-6.68-4.41c.55-.84,13.79-20.53,35.65-22.52,7.82-1.13,24.78,1.22,33.8,18.18l-7.06,3.75c-8.62-16.23-24.99-14.1-25.68-14.01l-.23.03c-18.13,1.6-29.68,18.8-29.8,18.97Z" />
              <path class="cls-1"
                d="M327.8,130.2l-1.23-7.91c.8-.13,19.62-3.29,18.67-18.44-.18-2.85-1.28-5.06-3.37-6.74-6.44-5.2-20.47-4.41-25.34-3.67l-1.21-7.91c.85-.13,21.04-3.12,31.56,5.34,3.89,3.13,6.02,7.33,6.35,12.48,1.12,17.97-16.14,25.42-25.43,26.84Z" />
              <path class="cls-1"
                d="M319.2,161.8l-4.27-6.76,2.14,3.38-2.15-3.37c.62-.4,15.27-10.07,12.59-29.31l7.92-1.1c3.4,24.45-15.43,36.66-16.23,37.17Z" />
              <path class="cls-1"
                d="M302.92,206.01l-3.88-6.99c23.46-13.04,13.57-41.71,13.14-42.92l7.53-2.71c4.5,12.48,6.78,39.53-16.78,52.62Z" />
              <path class="cls-1"
                d="M278.5,262.01l-3.9-6.99,1.95,3.49-1.95-3.49c.1-.06,10.05-5.7,18.4-15.45,10.75-12.56,13.95-25.81,9.51-39.4l7.6-2.49c5.32,16.26,1.45,32.61-11.19,47.28-9.28,10.77-19.96,16.79-20.41,17.04Z" />
              <path class="cls-1"
                d="M270.87,293.22l-2.96-7.43,1.48,3.72-1.51-3.7c.62-.26,15.28-6.75,13.66-33.29l7.99-.49c1.99,32.61-17.81,40.86-18.65,41.2Z" />
              <path class="cls-1"
                d="M267.71,312.1l-3.77-7.06,1.89,3.53-1.9-3.52c.64-.35,15.61-8.63,10.56-21.5l7.45-2.92c6.12,15.61-6.63,27.41-14.23,31.47Z" />
              <path class="cls-1"
                d="M271.23,332.32l-7.24-3.41c2.69-5.71,3.27-10.45,1.72-14.09-1.87-4.41-6.46-6.01-6.51-6.03l2.48-7.61c.32.1,7.97,2.64,11.33,10.37,2.54,5.85,1.94,12.84-1.79,20.76Z" />
              <path class="cls-1"
                d="M297.44,344.65c-18.35-19.93-42.25-4.75-43.26-4.09l-4.36-6.71c10.5-6.83,35.2-14.5,53.51,5.38l-5.88,5.42Z" />
              <path class="cls-1"
                d="M307.96,380.35c-2.01,0-4.14-.16-6.38-.49l1.16-7.92c7.08,1.03,12.44.1,15.95-2.79,3.81-3.14,4.41-7.87,4.49-8.75-.41-6.43-2.46-10.69-6.26-13.04-8.11-4.99-23-.38-28.05,1.82l-3.2-7.33c.9-.39,22.09-9.51,35.43-1.31,6.17,3.79,9.56,10.4,10.09,19.65v.18s0,.18,0,.18c-.01.36-.36,8.89-7.29,14.69-4.07,3.41-9.42,5.12-15.94,5.12Z" />
              <path class="cls-1"
                d="M277.73,386.87c-10.6,0-22.67-3.89-33.97-16.49-1.28-1.43-2.55-2.97-3.77-4.57l6.38-4.83c1.08,1.43,2.21,2.8,3.34,4.06,10.3,11.48,22.39,15.88,35.95,13.07,10.31-2.14,17.56-7.69,17.63-7.75l4.9,6.32c-.21.17-13.45,10.19-30.46,10.19Z" />
              <path class="cls-1"
                d="M235.23,374.14c-7.08,0-15.04-2.43-20.99-10.27l6.37-4.83c9.05,11.94,23.81,5.33,24.44,5.04l3.38,7.25c-3.11,1.46-7.92,2.81-13.21,2.81Z" />
              <path class="cls-1"
                d="M254.01,135.89c-2.83,0-4.69-.29-4.94-.33l1.3-7.89c.64.1,15.73,2.36,24.81-9.24l6.3,4.93c-8.5,10.86-20.71,12.54-27.47,12.54Z" />
              <path class="cls-1"
                d="M293.61,128.67c-15.45,0-22.44-9.84-22.74-10.27l6.58-4.55-3.29,2.28,3.27-2.31c.22.31,5.58,7.5,17.83,6.81,4.23-.24,8.84-1.39,13.7-3.43l3.09,7.38c-5.71,2.39-11.21,3.75-16.35,4.03-.71.04-1.41.06-2.09.06Z" />
              <path class="cls-1"
                d="M277.61,171.66c-6-7.42-8.27-15.19-6.74-23.1,3.18-16.42,21.88-27,22.67-27.45l3.9,6.99c-.16.09-16.27,9.25-18.72,22-1.07,5.55.6,10.96,5.11,16.52l-6.22,5.03Z" />
              <path class="cls-1"
                d="M259.09,217.01c-11.12-30.08,26.22-54.38,27.81-55.4l4.31,6.74c-.34.22-33.51,21.85-24.62,45.89l-7.5,2.77Z" />
              <path class="cls-1"
                d="M258.98,223.74l-7.76-1.94c2.29-9.16,17.4-12.51,20.4-13.09l1.51,7.86c-6.15,1.19-13.47,4.43-14.16,7.17Z" />
            </g>
            <g id="Z-2" data-name="Z">
              <path class="cls-1"
                d="M306.02,80.09l-7.9-1.23c.06-.39,1.55-9.58,10.18-13.79,8.14-3.97,19.36-2,33.36,5.86l-3.92,6.98c-11.35-6.37-20.3-8.34-25.86-5.68-4.87,2.33-5.82,7.65-5.86,7.87Z" />
              <path class="cls-1"
                d="M340.41,81.47l-6.92-4.02c.18-.32,18.83-31.36,58.11-10.75l-3.72,7.08c-32.58-17.09-46.89,6.67-47.48,7.69Z" />
              <path class="cls-1"
                d="M397.02,114.87l-3.8-7.04c.78-.42,19.16-10.69,14.56-27.31-1.1-3.99-2.92-6.44-5.54-7.47-5.2-2.05-12.96,1.57-15.52,3.13l-4.2-6.81c1.28-.79,12.79-7.63,22.62-3.78,5.07,1.98,8.55,6.29,10.35,12.79,5.04,18.23-9.88,31.83-18.47,36.48Z" />
              <path class="cls-1"
                d="M390.78,158.08l-4.45-6.65c.95-.64,23.14-16.05,12.12-43.75l7.43-2.96c13.45,33.81-14.81,53.17-15.1,53.36Z" />
              <path class="cls-1"
                d="M369.59,181.02l-1.17-7.91c8.43-1.25,13.71-4,15.68-8.17,2.86-6.05-1.78-13.89-1.83-13.97l6.83-4.16c.28.47,6.92,11.52,2.25,21.49-3.17,6.77-10.49,11.05-21.76,12.72Z" />
              <path class="cls-1"
                d="M355.39,224.6l-2.98-7.43,1.49,3.71-1.49-3.71c.2-.08,19.88-8.15,23.58-21.41,1.55-5.57.05-11.28-4.6-17.46l6.4-4.8c6.16,8.2,8.14,16.42,5.9,24.44-4.77,17.06-27.34,26.27-28.3,26.66Z" />
              <path class="cls-1"
                d="M350.95,255.91l-3.37-7.26c.55-.26,13.31-6.76,8.32-29.81l7.82-1.69c6.41,29.61-11.98,38.4-12.77,38.76Z" />
              <path class="cls-1"
                d="M338.33,300.93l-2.75-7.51,1.38,3.76-1.39-3.75s1.85-.7,4.26-2.2c4.34-2.69,9.84-7.5,11.43-15.11,1.69-8.06-1.29-17.73-8.86-28.73l6.59-4.53c8.86,12.87,12.25,24.62,10.1,34.91-2.17,10.38-9.38,16.75-15.04,20.27-3.1,1.92-5.46,2.81-5.72,2.91Z" />
              <path class="cls-1"
                d="M340.54,331.09l-4.05-6.9c4.59-2.69,7.17-5.92,7.9-9.89,1.51-8.25-5.49-17.07-5.56-17.16l6.21-5.04c.38.47,9.37,11.7,7.22,23.6-1.14,6.32-5.09,11.5-11.73,15.39Z" />
              <path class="cls-1"
                d="M360.19,350.98c-.57-3.19-1.38-6.04-2.42-8.46-2.27-5.32-5.57-8.69-10.06-10.28-10.06-3.56-22.69,3.03-22.82,3.1l-3.76-7.06c.64-.34,15.81-8.31,29.21-3.6,6.65,2.34,11.63,7.28,14.79,14.69,1.28,2.99,2.26,6.42,2.94,10.21l-7.88,1.4Z" />
              <path class="cls-1"
                d="M375.68,387.23c-2.14,0-4.06-.57-5.44-1.95l5.66-5.66c-.3-.3-.58-.42-.67-.44,1.37.35,6.87-1.11,10.87-5.42,3-3.24,5.94-9,2.85-18.02-1.84-5.38-4.84-9.08-8.92-10.99-7.68-3.6-17.04-.11-17.13-.08l-2.88-7.46c.51-.2,12.54-4.76,23.35.26,6.15,2.86,10.58,8.14,13.16,15.68,6.35,18.57-6.01,28.04-9.96,30.49-3.3,2.06-7.39,3.58-10.88,3.58Z" />
              <path class="cls-1"
                d="M352.74,394.69c-5.88,0-11.57-1.97-16.99-5.89-2.07-1.5-4.12-3.3-6.09-5.36l5.78-5.53c1.64,1.72,3.32,3.2,5,4.41,5.16,3.73,10.32,5.05,15.81,4.04,13.28-2.45,23.5-17.85,23.6-18.01l6.7,4.37c-.49.75-12.14,18.4-28.82,21.5-1.68.31-3.34.47-5,.47Z" />
              <path class="cls-1"
                d="M326.82,391.43c-3.61,0-7.52-.58-11.06-2.37-4.75-2.39-8.01-6.44-9.68-12.01l7.66-2.3c1.03,3.43,2.86,5.77,5.6,7.16,6.96,3.53,17.31-.08,17.41-.12l2.7,7.53c-.36.13-5.98,2.11-12.63,2.11Z" />
              <path class="cls-1"
                d="M354.44,116.1c-1.95,0-3.96-.09-6.04-.26l.68-7.97c7.64.65,14.18-.04,19.46-2.03,7.46-2.83,9.8-7.4,9.82-7.44l7.29,3.29c-.35.77-3.69,7.63-14.28,11.63-4.9,1.86-10.58,2.79-16.93,2.79Z" />
              <path class="cls-1"
                d="M336.71,205.77c-7.39-8.32-10.27-16.78-8.54-25.15,2.52-12.21,14.29-20.35,19.94-23.62-9.02-36.23,19.78-50.87,20.08-51.02l3.53,7.18-1.76-3.59,1.78,3.58c-1.01.51-24.67,12.79-15.09,44.72l.99,3.31-3.13,1.46c-.16.07-16.06,7.7-18.5,19.62-1.18,5.75,1.07,11.88,6.69,18.19l-5.98,5.31Z" />
              <path class="cls-1"
                d="M321.3,261.04c-7.55-13.75-6.49-29.37,3.06-45.18,6.97-11.54,15.98-19.09,16.36-19.41l5.11,6.15-2.56-3.08,2.56,3.07c-.33.27-32.4,27.48-17.52,54.58l-7.01,3.85Z" />
              <path class="cls-1"
                d="M313.53,302.7c-1-1.89-1.85-3.81-2.52-5.71-7.84-22.35,9.62-41.02,19.95-47.78l4.38,6.69c-1,.66-24.49,16.44-16.78,38.44.53,1.52,1.22,3.07,2.03,4.6l-7.06,3.76Z" />
              <path class="cls-1"
                d="M286.69,335.15c-7.04-25.58,24.9-42.34,26.27-43.04l3.66,7.11-1.83-3.56,1.83,3.56c-.28.14-27.53,14.49-22.22,33.81l-7.71,2.12Z" />
            </g>
            <g id="E">
              <path class="cls-1"
                d="M379.8,66.8c-.04-1.14-.21-11.29,5.75-16.64,1.97-1.76,5.42-3.71,10.73-2.95l-1.13,7.92c-2.44-.35-3.59.39-4.25.97-2.71,2.42-3.16,8.49-3.11,10.43l-8,.27Z" />
              <path class="cls-1"
                d="M394.4,57.48c-.21-.5-5.08-12.42-.05-21.35,2.64-4.69,7.31-7.58,13.87-8.57,16.44-2.49,32.87-2.6,41.64,13.35l-7.01,3.86c-5.31-9.66-14.37-12.18-33.43-9.29-4.05.61-6.69,2.11-8.09,4.57-2.59,4.57-.55,11.93.46,14.38l-7.39,3.07Z" />
              <path class="cls-1"
                d="M440.68,85.63c-4.34,0-8.71-.64-12.89-2.07l2.59-7.57c13.56,4.64,30.98-1.45,37.5-9.98,3-3.92,3.26-7.76.79-11.39-9.11-13.43-25.09-5.05-25.77-4.69l-3.82-7.03c7.9-4.31,25.64-8.35,36.21,7.22,4.42,6.51,4.04,14.07-1.06,20.74-6.35,8.32-19.81,14.77-33.56,14.77Z" />
              <path class="cls-1"
                d="M420.93,103.36l-1.61-7.84c.38-.09,9.38-2.25,11.79-15.29l7.87,1.46c-3.43,18.54-17.45,21.55-18.04,21.66Z" />
              <path class="cls-1"
                d="M419.25,135.02l-4.18-6.82,2.09,3.41-2.12-3.4c.58-.37,14.11-9.23,7.09-30.33l7.59-2.52c7.26,21.84-3.68,35.49-10.48,39.66Z" />
              <path class="cls-1"
                d="M410.46,168.4l-5.66-5.66c16.05-16.05,6.85-33.85,6.46-34.6l7.04-3.8c.13.23,12.51,23.71-7.84,44.06Z" />
              <path class="cls-1"
                d="M429.17,175.4c-7.64-5.35-14.52-7.51-20.44-6.42-6.06,1.11-9.08,5.35-9.11,5.39l-6.62-4.49c.19-.29,4.87-7.03,14.28-8.77,8.11-1.49,17.01,1.11,26.48,7.74l-4.59,6.55Z" />
              <path class="cls-1"
                d="M422.12,216.22c-6.89,0-14.32-1.65-21.87-6.08l4.05-6.9c23.71,13.92,45.94-5.12,48.14-7.1,1.77-2.07,5.81-8.05,5.15-13.1-.33-2.5-1.8-4.6-4.5-6.4-9.86-6.58-27.66,2.49-33.68,6.54l-4.47-6.63c1.06-.72,26.24-17.46,42.59-6.57,4.61,3.08,7.38,7.24,8,12.05,1.27,9.86-6.88,19.16-7.23,19.55l-.27.27c-.21.19-15.74,14.35-35.9,14.35Z" />
              <path class="cls-1"
                d="M418.03,252.52l-6.49-4.67,3.25,2.34-3.25-2.33c.59-.83,14.38-20.49.85-32.81l5.39-5.91c15.05,13.71,6.75,34.38.25,43.39Z" />
              <path class="cls-1"
                d="M413.46,300.29l-3.33-7.27,1.66,3.64-1.69-3.63c.31-.15,7.7-3.73,10.63-12.86,2.81-8.77.62-19.71-6.51-32.51l6.99-3.89c8.3,14.9,10.69,28.03,7.08,39.04-4.08,12.45-14.4,17.29-14.84,17.49Z" />
              <path class="cls-1"
                d="M417.36,341.39l-6.35-4.86c5.73-7.49,8.04-14.82,6.84-21.81-1.97-11.55-12.89-18.81-13-18.88l4.35-6.71c.57.37,13.91,9.14,16.52,24.17,1.61,9.29-1.2,18.75-8.36,28.1Z" />
              <path class="cls-1"
                d="M439.53,350.14c-1.34-.8-2.72-1.54-4.11-2.2-17.35-8.22-32.73-2.33-32.88-2.27l-2.93-7.44c.76-.3,18.79-7.2,39.24,2.49,1.62.77,3.24,1.63,4.8,2.57l-4.12,6.86Z" />
              <path class="cls-1"
                d="M448.92,391.43c-3.01,0-6.1-.29-9.24-.87l1.45-7.87c4.33.8,8.51.95,12.41.45,9.13-1.16,16.41-5.91,19.47-12.69,2.53-5.59,1.83-11.75-1.95-17.33-10.4-15.35-31.3-5.62-32.18-5.19l-3.47-7.21c9.62-4.64,30.84-8.96,42.27,7.92,5.32,7.86,6.28,17.01,2.62,25.11-4.22,9.33-13.84,15.81-25.75,17.33-1.83.23-3.71.35-5.62.35Z" />
              <path class="cls-1"
                d="M418.57,404.93c-3.86,0-7.98-.38-12.34-1.27-13.95-2.85-22.87-7.58-26.51-14.05-2.74-4.89-1.41-8.95-1.25-9.4l7.53,2.71s.02-.05.05-.16c-.01.06-.33,1.37.82,3.22,1.49,2.38,6.19,6.83,20.96,9.84,26.55,5.42,42.65-10.79,43.32-11.48l5.77,5.54c-.68.71-14.74,15.05-38.36,15.05Z" />
              <path class="cls-1"
                d="M384.12,233.51c-12.19-10.84-9.75-29-9.64-29.77l7.92,1.13-3.96-.57,3.96.56c-.02.15-1.91,14.72,7.04,22.67l-5.32,5.98Z" />
              <path class="cls-1"
                d="M387.97,284.43c-.24-.27-5.88-6.64-8.85-17.56-2.71-9.97-3.67-25.85,7.16-44.89l6.95,3.96c-17.91,31.48-.07,52.29.7,53.16l-5.96,5.33Z" />
              <path class="cls-1"
                d="M368.63,339.46c-.3-1.05-2.33-9.93,10.99-20.24-2.84-3.67-6.52-9.91-6.92-17.95-.45-9.04,3.3-17.92,11.14-26.4l5.87,5.43c-6.3,6.81-9.34,13.7-9.03,20.48.44,9.59,7.5,16.06,7.57,16.12l3.86,3.48-4.33,2.85c-12.34,8.07-11.42,14.15-11.41,14.21-.02-.12-.05-.18-.05-.18l-7.69,2.2Z" />
            </g>
            <g id="R">
              <path class="cls-1"
                d="M452.66,122.7c-.66-.57-16.02-14.41-9.61-42.63l7.8,1.77c-5.37,23.62,6.95,34.72,7.08,34.83l-5.27,6.02Z" />
              <path class="cls-1"
                d="M440.79,171.06c-9.56-27.42,14.54-57.4,15.57-58.66l6.2,5.06s0,0,0,0h0c-.33.41-22.28,27.85-14.22,50.97l-7.55,2.63Z" />
              <path class="cls-1"
                d="M450.6,242.2c-.46-.49-11.22-12.14-11.22-34.92h8c0,19.41,8.96,29.35,9.05,29.45l-5.83,5.48Z" />
              <path class="cls-1"
                d="M491.01,47.25c-5.72-6.53-11.7-9.86-17.8-9.88h-.07c-9.27,0-15.99,7.45-16.06,7.52l-5.98-5.32c.37-.42,9.22-10.21,22.04-10.21h.1c8.49.03,16.49,4.28,23.78,12.61l-6.02,5.27Z" />
              <path class="cls-1"
                d="M532.01,90.94l-5.66-5.66c.09-.09,9.39-11.72-1.11-27.75-9.49-14.49-33.9-6.81-34.15-6.73l-2.48-7.61c1.24-.4,30.47-9.66,43.31,9.95,12.45,19,3.23,34.64.08,37.79Z" />
              <path class="cls-1"
                d="M528.51,138.45l-4.62-6.53,2.31,3.27-2.32-3.26c.1-.07,10.17-7.41,12.08-19.45,1.28-8.05-1.32-16.55-7.72-25.27l6.45-4.74c7.74,10.54,10.82,21.09,9.16,31.37-2.5,15.38-14.81,24.24-15.33,24.61Z" />
              <path class="cls-1"
                d="M522.57,181.09l-3.49-7.2c.09-.04,8.73-4.4,11.97-14.12,2.54-7.64,1.21-16.67-3.95-26.85l7.13-3.62c6.2,12.22,7.67,23.36,4.36,33.14-4.37,12.92-15.55,18.42-16.02,18.65Z" />
              <path class="cls-1"
                d="M494.89,223.1l-1.74-7.81c11.6-2.58,18.93-11.08,21.78-25.29,2.26-11.27.76-21.96.75-22.07l7.92-1.16c2.45,16.73-.39,50.03-28.71,56.32Z" />
              <path class="cls-1"
                d="M522.22,249.63l-6.35-4.87,3.17,2.43-3.19-2.41c.15-.2,3.57-4.9,2.12-10.76-1.35-5.45-6.73-10.57-15.55-14.8l3.46-7.21c11.28,5.41,17.98,12.22,19.9,20.24,2.3,9.6-3.31,17.06-3.55,17.38Z" />
              <path class="cls-1"
                d="M539.42,289.87l-7.37-3.1,3.69,1.55-3.69-1.55c.52-1.25,12.5-30.77-11.27-43.17l3.7-7.09c24.26,12.66,20.38,40.44,14.94,53.36Z" />
              <path class="cls-1"
                d="M555.79,343.49l-6.75-4.29c1.46-2.3,2.67-4.58,3.59-6.8,3.01-7.24,3.14-13.8.37-20.04-6.48-14.63-26.74-21.99-26.94-22.07l2.67-7.54c.96.34,23.66,8.54,31.57,26.33,3.67,8.25,3.57,17.13-.28,26.39-1.1,2.63-2.52,5.33-4.22,8.01Z" />
              <path class="cls-1"
                d="M547.98,400.98c-5.81,0-16-1.24-22.43-9.1-7.03-8.6-7.28-22.24-.74-40.56l7.53,2.69c-5.45,15.25-5.66,26.59-.63,32.78,5.33,6.55,15.5,6.34,18.61,6.1,13.44-9.96,20.14-20.1,19.9-30.15-.36-14.97-15.95-25.34-16.1-25.45l4.38-6.69c.79.51,19.26,12.83,19.72,31.95.31,13.05-7.78,25.65-24.06,37.45l-.8.58-.98.14c-.22.03-1.91.26-4.41.26Z" />
              <path class="cls-1"
                d="M530.73,357.31c-6.5-3.81-10.2-8.56-10.97-14.12-1.18-8.4,5.07-15.05,5.34-15.33l5.8,5.51s-3.87,4.22-3.21,8.76c.44,3,2.82,5.78,7.09,8.29l-4.05,6.9Z" />
              <path class="cls-1"
                d="M522.79,339.19c-24.82-13.57-14.61-52.65-14.16-54.3l7.72,2.08-3.86-1.04,3.86,1.04c-.09.35-9.03,34.65,10.27,45.2l-3.84,7.02Z" />
              <path class="cls-1"
                d="M514.93,292.15c-.37-.11-9.11-2.8-14.09-12.23-4.3-8.15-4.47-18.57-.5-30.96l7.62,2.44c-3.27,10.19-3.31,18.48-.12,24.65,3.35,6.47,9.15,8.36,9.4,8.44l-2.31,7.66Z" />
              <path class="cls-1"
                d="M500.63,263.68c-12.48-1.72-21.39-6.51-26.47-14.23-7.08-10.76-3.8-23.05-3.65-23.57l7.71,2.13-3.86-1.07,3.86,1.05c-.02.09-2.44,9.44,2.66,17.12,3.77,5.67,10.78,9.25,20.84,10.64l-1.09,7.92Z" />
              <path class="cls-1"
                d="M480.92,315.41l-5.96-5.34,2.98,2.67-2.99-2.66c1.02-1.14,24.6-28.36.94-58.32l6.28-4.96c27.88,35.3-.96,68.27-1.25,68.6Z" />
              <path class="cls-1"
                d="M484.76,345.46l-6.5-4.66c.89-1.24,1.69-2.46,2.37-3.63,3.33-5.69,4.16-10.36,2.48-13.89-3.13-6.54-14-8.35-14.11-8.37l1.19-7.91c.62.09,15.15,2.4,20.13,12.83,2.9,6.07,1.96,13.27-2.79,21.38-.81,1.38-1.74,2.81-2.77,4.25Z" />
              <path class="cls-1"
                d="M466.17,408.59c-.06,0-.12,0-.18,0-8.39-.06-16.19-3.93-23.17-11.51l5.88-5.42c5.4,5.87,11.22,8.87,17.29,8.93,9.17.08,16.66-6.53,18.26-8.07,8.35-15.03,11.12-28.04,8-37.63-2.84-8.75-9.53-11.92-9.82-12.05l3.26-7.31c.41.18,10.06,4.56,14.09,16.65,4.03,12.08,1.06,27.14-8.83,44.74l-.24.43-.34.36c-.42.45-10.45,10.87-24.2,10.88Z" />
              <path class="cls-1"
                d="M442.17,341.91c-.16-.32-15.31-32.06,7.36-54.08l5.57,5.74c-18.47,17.95-6.27,43.74-5.75,44.83l-7.19,3.51Z" />
              <path class="cls-1"
                d="M452.08,299.04c-.28-.37-27.39-37.76,3.46-66.67l5.47,5.84c-25.61,24.01-3.45,54.78-2.5,56.08l-6.43,4.76Z" />
              <path class="cls-1"
                d="M498.31,172.35c-4.28-7.36-8.37-24.86,6.75-38.36l5.33,5.97c-13.97,12.47-5.52,27.75-5.15,28.39l-6.93,4Z" />
              <path class="cls-1"
                d="M512.86,144.6c-8.35-4.87-22.71-19.06-17.32-37.92l7.69,2.2c-5.02,17.56,12.9,28.37,13.67,28.82l-4.04,6.91Z" />
              <path class="cls-1"
                d="M491.04,113.17c-.94,0-1.87-.1-2.75-.32l1.94-7.76c3.54.89,11.75-4,16.18-7.57l5.03,6.22c-2.11,1.71-12.12,9.44-20.4,9.44Z" />
              <path class="cls-1"
                d="M485.48,127.2l-6.75-4.29c6.05-9.51,7.98-17.03,5.59-21.74-3.04-6-12.49-6.67-12.59-6.68l.47-7.99c.57.03,14.07.92,19.22,10.99,3.83,7.49,1.83,17.48-5.95,29.71Z" />
              <path class="cls-1"
                d="M484.56,174.12l-6.1-5.17c1.15-1.36,2.19-2.75,3.1-4.13,14.52-22.18-7.63-41.26-8.58-42.06l5.14-6.13c9.95,8.33,24.88,30.04,10.13,52.57-1.08,1.65-2.32,3.31-3.69,4.92Z" />
              <path class="cls-1"
                d="M488.35,169.05l-6.91-4.04c.3-.52,3.08-5.11,8.49-6.25,2.53-.53,6.53-.45,11.22,2.99l-4.73,6.45c-1.83-1.34-3.45-1.89-4.8-1.62-1.96.39-3.27,2.45-3.28,2.47Z" />
            </g>
            <g id="B-2" data-name="B">
              <path class="cls-1"
                d="M584.34,68.06l-7.84-1.6,3.92.8-3.92-.78s.65-3.37.15-7.6c-1.49-12.83-10.96-19.1-28.11-18.6-1.45.19-14.18,2.2-16,14.47l-7.91-1.17c2.25-15.16,16.16-20.54,23.21-21.27l.29-.02c29.05-.89,35.32,15.7,36.48,25.67.65,5.56-.22,9.93-.26,10.11Z" />
              <path class="cls-1"
                d="M601.74,119.42l-4.51-6.61c10.87-7.41,11.34-17.37,11.36-18.1-1.1-32.52-26.79-32.32-27.88-32.3l-.18-8c.34,0,34.75-.31,36.06,40.13v.13c0,.6-.17,14.74-14.85,24.75Z" />
              <path class="cls-1"
                d="M601.51,165.02l-5.24-6.05,2.62,3.02-2.63-3.01c.81-.72,19.8-17.91,6.81-47.93l7.34-3.18c15.39,35.55-8.65,56.93-8.9,57.14Z" />
              <path class="cls-1"
                d="M568.99,210.11c-.58,0-1.17,0-1.77-.02l.19-8c12.53.29,21.17-2.87,25.67-9.39,9.05-13.15-.17-36.1-.27-36.33l7.4-3.03c.45,1.1,10.93,27.23-.55,43.9-5.89,8.55-16.2,12.88-30.68,12.88Z" />
              <path class="cls-1"
                d="M606.46,237.29l-7.99-.44,3.99.22-3.99-.21c.01-.23,1-22.89-13.74-30.05l3.5-7.2c19.55,9.49,18.3,36.53,18.23,37.68Z" />
              <path class="cls-1"
                d="M624.82,294.65l-7.76-1.94c4.7-18.81,3.51-33.21-3.55-42.79-8.09-10.97-21.26-11.83-21.39-11.83l.44-7.99c.69.04,17.09,1.1,27.39,15.07,8.54,11.59,10.18,28.24,4.87,49.47Z" />
              <path class="cls-1"
                d="M616.66,352.72l-3.37-7.26,1.68,3.63-1.7-3.62c.11-.05,11.22-5.44,14.85-17.81,3.1-10.56.11-23.26-8.91-37.72l6.79-4.23c10.31,16.55,13.59,31.47,9.75,44.34-4.72,15.84-18.52,22.4-19.11,22.67Z" />
              <path class="cls-1"
                d="M576.76,411.53c-9.36,0-19.79-3.12-26.26-13.56l6.8-4.21c9.77,15.78,31.6,7.69,31.82,7.6l.13-.05c10.06-3.45,17.08-9.35,20.86-17.53,8-17.3-.56-39.56-.65-39.79l7.44-2.94c.41,1.04,9.91,25.6.49,46.04-4.72,10.24-13.3,17.56-25.49,21.76-3.77,1.43-9.25,2.67-15.15,2.67Z" />
              <path class="cls-1"
                d="M573.36,352.27l-5.54-5.77c1.67-1.6,16.69-15.52,29.89-11.35l-2.41,7.63c-7.39-2.34-18.6,6.29-21.94,9.5Z" />
              <path class="cls-1"
                d="M597.76,345.27c-.79-.84-19.19-20.93-12.93-54.71l7.87,1.46c-5.53,29.84,10.74,47.6,10.9,47.78l-5.84,5.47Z" />
              <path class="cls-1"
                d="M596.03,297.53c-.95-.26-23.33-6.66-31.75-24.81l7.26-3.37c6.89,14.84,26.44,20.42,26.64,20.47l-2.14,7.71Z" />
              <path class="cls-1"
                d="M567.24,287.27l-7-3.87c10.83-19.6-1.78-32.83-2.32-33.38l5.67-5.64c6.09,6.09,14.7,22.9,3.65,42.9Z" />
              <path class="cls-1"
                d="M565.96,339.25l-6.83-4.16c20.88-34.26-2.61-51.12-3.62-51.82l4.52-6.6c.31.21,30.86,21.68,5.93,62.59Z" />
              <path class="cls-1"
                d="M564.68,162.2l-7.84-1.58c.27-1.36,2.91-13.38,11.69-16.39,5.14-1.77,10.83-.01,16.89,5.21l-5.22,6.06c-2.58-2.22-6.24-4.67-9.06-3.71-3.79,1.29-5.98,8.12-6.45,10.41Z" />
              <path class="cls-1"
                d="M578.2,153.2c-12.36-20.98-1.09-40.13-.61-40.94l6.85,4.13-3.43-2.07,3.43,2.05c-.38.64-9.24,15.98.64,32.76l-6.89,4.06Z" />
              <path class="cls-1"
                d="M585.56,119.86c-.76-.29-18.71-7.22-26.48-20.82l6.95-3.97c6.33,11.08,22.22,17.25,22.38,17.31l-2.84,7.48Z" />
              <path class="cls-1"
                d="M560.76,117.91l-7.16-3.57c.1-.21,10.2-20.93-1.74-29.53l4.67-6.49c17.46,12.57,4.77,38.5,4.22,39.59Z" />
              <path class="cls-1"
                d="M566.99,154.13l-7.71-2.15c6.34-22.73-8.28-35.87-8.9-36.42l5.24-6.05c.78.68,19.11,16.87,11.37,44.62Z" />
              <path class="cls-1"
                d="M533.18,243.63h-8c0-.96.09-23.7,8.21-37.11,1.9-3.14,4.13-5.6,6.65-7.31l4.51,6.61c-1.56,1.06-3.01,2.69-4.31,4.84-6.95,11.49-7.05,32.75-7.05,32.97Z" />
              <path class="cls-1"
                d="M533.73,211.13c-.56-.68-13.61-16.86-7.24-42.36l7.76,1.94c-5.36,21.43,5.2,34.78,5.65,35.34l-6.18,5.08Z" />
            </g>
            <g id="E-2" data-name="E">
              <path class="cls-1"
                d="M606.63,69.54l-7.6-2.5c.34-1.03,8.55-25.34,25.06-24.63l-.35,7.99c-8.69-.35-15.31,13.69-17.11,19.14Z" />
              <path class="cls-1"
                d="M670.19,55.23l-7.94-.96s.59-6.01-3.34-10.72c-3.05-3.65-8.1-5.76-15.03-6.26-6.16-.45-10.49.73-12.86,3.5-3.73,4.36-2.05,11.76-2.03,11.84l-7.78,1.88c-.11-.46-2.67-11.36,3.68-18.86,4.11-4.85,10.69-6.99,19.57-6.33,9.24.68,16.2,3.78,20.68,9.23,6.09,7.4,5.09,16.31,5.04,16.69Z" />
              <path class="cls-1"
                d="M672.05,84.38c-13.27,0-26.13-4.74-26.89-5.03l2.81-7.49c.24.09,23.82,8.76,37.03,1.94,3.98-2.05,6.51-5.31,7.75-9.95,1.3-4.89.93-8.4-1.11-10.44-5.14-5.14-19.96-2.66-24.75-1.38l-2.06-7.73c2.35-.62,23.18-5.83,32.47,3.46,4.2,4.2,5.27,10.31,3.18,18.16-1.83,6.86-5.81,11.91-11.83,15.01-4.97,2.56-10.82,3.46-16.6,3.46Z" />
              <path class="cls-1"
                d="M631.78,94.28l-2.62-7.56,1.31,3.78-1.32-3.77c.54-.19,13.15-4.83,14.63-18.12l7.95.88c-2.04,18.39-19.22,24.54-19.95,24.79Z" />
              <path class="cls-1"
                d="M638.57,124.88l-6.67-4.41,3.34,2.21-3.34-2.2c.11-.17,10.82-16.88,1.95-31.29l6.81-4.19c11.56,18.8-1.53,39.03-2.09,39.88Z" />
              <path class="cls-1"
                d="M635.94,163.95l-6.16-5.1c12.36-14.94-2.05-41.11-2.19-41.37l6.97-3.92c.71,1.27,17.25,31.21,1.38,50.39Z" />
              <path class="cls-1"
                d="M639.41,175.7l-7.16-3.58c.93-1.87-2.31-8.75-6.68-14.17l6.22-5.03c2.76,3.4,11.45,15.11,7.62,22.78Z" />
              <path class="cls-1"
                d="M632.63,180.26l-4.32-6.73c.66-.43,16.41-10.43,29.07-7.66,4.71,1.03,8.42,3.61,11.02,7.68l-6.74,4.31c-7.26-11.34-28.81,2.26-29.03,2.4Z" />
              <path class="cls-1"
                d="M660.37,207.46c-7.95,0-15.31-2.5-19.91-5.02l3.84-7.02-1.92,3.51,1.91-3.51c.89.48,21.76,11.51,34.97-4.89.4-1.61,1.27-6.41-1.3-10.05-3.09-4.38-10.09-5.43-15.41-5.55l.17-8c10.47.23,17.81,3.26,21.83,9.01,5.44,7.8,2.26,17.33,2.13,17.74l-.21.62-.4.52c-7.31,9.57-16.88,12.65-25.71,12.65Z" />
              <path class="cls-1"
                d="M634.1,219.12c-2.68,0-5.1-.33-6.99-.81l1.96-7.76-.98,3.88.97-3.88c.64.16,15.73,3.68,21.49-9.36l7.32,3.24c-5.13,11.59-15.62,14.69-23.76,14.69Z" />
              <path class="cls-1"
                d="M635.42,257.99l-6.33-4.89,3.17,2.44-3.17-2.44c.58-.76,14.04-18.74,2.34-34.51l6.42-4.77c12.27,16.53,3.77,36.11-2.43,44.15Z" />
              <path class="cls-1"
                d="M646.93,312.54l-6.71-4.35s1.1-1.71,2.45-4.5c5.81-11.97,12.28-34.16-9.69-48.25l4.32-6.73c28.19,18.08,18.18,46.92,12.57,58.48-1.59,3.27-2.88,5.27-2.93,5.35Z" />
              <path class="cls-1"
                d="M655.97,350.51l-6.9-4.04c12.52-21.38-4.74-37.4-5.48-38.07l5.37-5.93c.23.21,22.77,21.12,7.01,48.04Z" />
              <path class="cls-1"
                d="M685.74,399.42l-2.11-7.72s-.07.02-.1.03c2.7-1.13,14.2-14.58,14.58-27.74.21-7.29-3.19-12.55-10.42-16.09-24.32-11.9-38.34,10.08-38.93,11.02l-6.81-4.19c6.22-10.14,24.52-26.12,49.26-14.01,13.58,6.64,15.53,17.56,14.77,25.54-1.38,14.63-13.64,31.35-20.24,33.15Z" />
              <path class="cls-1"
                d="M650.09,420c-5.43,0-10.1-1.55-13.96-4.65-10.14-8.13-10.26-23.89-10.26-24.56h8c0,.12.14,12.63,7.29,18.34,3.54,2.83,8.47,3.56,14.66,2.19,23.6-5.24,26.58-23.87,26.7-24.66l7.92,1.09c-.14,1.01-3.69,24.88-32.89,31.37-2.63.58-5.12.87-7.47.87Z" />
              <path class="cls-1"
                d="M628.9,404.21c-8.32-2.08-17.67-14.63-18.71-16.05l6.46-4.72c3.52,4.81,10.24,12.02,14.19,13.01l-1.94,7.76Z" />
              <path class="cls-1"
                d="M606.46,235.58l-7.98-.58c.2-2.67,2.16-26.32,10.94-33.75l5.17,6.11c-4.46,3.77-7.44,18.77-8.13,28.22Z" />
              <path class="cls-1"
                d="M616.95,211.15c-.64-.17-15.78-4.28-21.8-20.34l7.49-2.81c4.53,12.08,15.87,15.28,16.35,15.41l-2.04,7.74Z" />
            </g>
            <g id="A">
              <path class="cls-1"
                d="M730.24,72.29c-2.31-10.58-6.38-17.09-12.09-19.35-8.52-3.37-18.67,3.48-18.78,3.55l-4.55-6.58c.55-.38,13.71-9.34,26.22-4.43,8.36,3.29,14.08,11.73,17.01,25.1l-7.82,1.71Z" />
              <path class="cls-1"
                d="M742.78,116.04l-6.54-4.61c.12-.18,12.41-17.9,8.45-30.9-1.56-5.12-5.48-8.83-11.97-11.32l2.87-7.47c8.83,3.4,14.47,8.94,16.76,16.48,5.09,16.76-8.96,36.97-9.56,37.82Z" />
              <path class="cls-1"
                d="M747.28,166.12l-7.2-3.48c.68-1.41,15.47-33.05-7.13-49.87l4.78-6.42c11.66,8.68,16.98,21.88,15.37,38.16-1.17,11.89-5.63,21.22-5.82,21.61ZM743.68,164.38l-3.6-1.74s0,0,0,0l3.6,1.74Z" />
              <path class="cls-1"
                d="M749.97,203.66l-5.44-5.87,2.72,2.93-2.73-2.93c.9-.85,21.91-21.02.72-44.47l5.94-5.36c26.54,29.37-.93,55.44-1.21,55.7Z" />
              <path class="cls-1"
                d="M751.41,251.99l-7.13-3.63c15.68-30.82-3.23-50.55-4.04-51.38l5.67-5.65c.24.24,23.89,24.52,5.5,60.65Z" />
              <path class="cls-1"
                d="M750.19,311.04l-7.06-3.75c18.81-35.4-2.94-63.27-3.17-63.55l6.25-5c1.04,1.3,25.23,32.3,3.98,72.3Z" />
              <path class="cls-1"
                d="M773.19,367.45l-6.59-4.54,3.29,2.27-3.3-2.26c.11-.16,10.51-15.73,3.23-31.84-7.82-17.31-21.19-27.22-21.32-27.31l4.72-6.46c.62.45,15.22,11.27,23.89,30.48,9.17,20.3-3.39,38.89-3.93,39.67Z" />
              <path class="cls-1"
                d="M748.99,401.52c-7.29,0-13.52-3.91-18.16-11.45-10.69-17.36-10.05-49.16-1.75-61.14l6.58,4.55c-6.62,9.56-6.71,38.27,1.99,52.39,3.57,5.8,7.9,8.25,13.24,7.51,4.08-.57,7.1-2.41,9.22-5.61,6.5-9.82,3.05-30.07,1.17-37.05l7.72-2.08c.31,1.16,7.6,28.69-2.22,43.54-3.42,5.17-8.39,8.24-14.79,9.13-1.01.14-2.01.21-2.99.21Z" />
              <path class="cls-1"
                d="M728.17,340.98c-15.48-4.93-12.46-36.7-11.7-43.02l7.94.96c-1.51,12.61-.6,32.28,6.18,34.44l-2.43,7.62Z" />
              <path class="cls-1"
                d="M714.05,304.25c-5.93,0-12.42-1.52-18.2-6.3l5.09-6.17c11.51,9.51,28.18.83,28.35.74l3.75,7.07c-.55.29-8.95,4.66-18.99,4.66Z" />
              <path class="cls-1"
                d="M684.6,405.08c-2,0-3.29-.17-3.46-.2l1.13-7.92-.57,3.96.55-3.96c.1.01,10.11,1.28,17.76-4.96,5.45-4.44,8.57-11.72,9.27-21.63.01-1.47-.06-23.51-12.88-28.26l-4.44-1.64,2.36-4.1c.12-.21,12.28-21.85,4-37.7l7.09-3.71c8.07,15.42,1.37,33.8-2.23,41.61,14.49,9.09,14.13,32.96,14.11,34.05v.18c-.86,12.31-4.99,21.55-12.3,27.46-7.27,5.88-15.75,6.81-20.39,6.81Z" />
              <path class="cls-1"
                d="M666.45,340.22c-.24-1.41-5.61-34.76,18.41-45.44l3.25,7.31c-18.4,8.18-13.82,36.5-13.77,36.79l-7.89,1.34Z" />
              <path class="cls-1"
                d="M678.53,303.25c-10.84-14.27-12.87-32.41-5.88-52.45,5.15-14.76,13.32-25.28,13.66-25.73l6.3,4.93c-.42.54-29.92,39.16-7.71,68.4l-6.37,4.84ZM692.61,230l-3.15-2.47,3.15,2.46s0,0,0,0h0Z" />
              <path class="cls-1"
                d="M691.91,239.81c-.6-.64-14.72-15.93-16.27-42.86l7.99-.46c1.38,23.89,13.99,37.71,14.12,37.85l-5.83,5.47Z" />
              <path class="cls-1"
                d="M729.14,246.65c-.12-.11-12.43-10.61-22.85-8.63l-1.5-7.86c14.26-2.71,28.97,9.91,29.59,10.44l-5.25,6.04,2.62-3.02-2.62,3.02Z" />
              <path class="cls-1"
                d="M729.44,239.13l-8-.08c.26-24.71,15.22-31.58,15.86-31.86l3.27,7.3c-.45.21-10.93,5.36-11.13,24.64Z" />
              <path class="cls-1"
                d="M732.71,215.66c-15.46-13.44-9.81-33.67-9.56-34.52l7.69,2.22c-.19.66-4.43,16.22,7.12,26.27l-5.25,6.04Z" />
              <path class="cls-1"
                d="M731.25,186.31c-4.66,0-16.16-.91-22.88-9.3l6.25-5c5.7,7.13,17.88,6.27,18,6.26l.65,7.97c-.13.01-.87.07-2.03.07Z" />
              <path class="cls-1"
                d="M712.3,193.71l-7.55-2.66c9.75-27.7-2.07-33.5-2.58-33.73l3.09-7.38c.8.33,19.47,8.4,7.03,43.76Z" />
              <path class="cls-1"
                d="M717.73,235.19l-7.69-2.2c9.48-33.19-9.25-40.72-10.05-41.03l2.74-7.52c.29.1,7.12,2.62,12.27,10.43,4.54,6.88,8.7,19.47,2.74,40.32Z" />
              <path class="cls-1"
                d="M674.15,174.24c-.08-.98-1.81-24.16,10.27-34.89l5.32,5.98c-7.54,6.71-8.05,22.68-7.61,28.27l-7.97.64Z" />
              <path class="cls-1"
                d="M685.44,150.53c-10.04-10.04-13.37-23.78-9.63-39.72,2.73-11.63,8.26-20.32,8.5-20.69l6.73,4.33-3.36-2.16,3.37,2.16c-.8,1.25-19.42,30.95.06,50.43l-5.66,5.66Z" />
              <path class="cls-1"
                d="M690.22,99.78c-.52-.35-12.75-8.65-11.29-21.07l7.95.93c-.89,7.53,7.7,13.43,7.79,13.49l-4.45,6.65Z" />
            </g>
            <g id="T">
              <path class="cls-1"
                d="M761.83,71.31c-10.47-15.22-24.49-6.54-26.05-5.5l-4.47-6.63c7.52-5.1,24.97-10.07,37.12,7.6l-6.59,4.53Z" />
              <path class="cls-1"
                d="M795.04,82.98c-10.51-18.02-31.55-8.89-32.44-8.5l-3.29-7.29c9.79-4.43,31.31-7.67,42.64,11.76l-6.91,4.03Z" />
              <path class="cls-1"
                d="M838.83,102.68l-7.98-.53,3.99.26-3.99-.25c0-.1.48-10.02-6.61-16.54-5.93-5.45-15.55-7.28-28.59-5.43l-1.12-7.92c15.6-2.21,27.44.33,35.22,7.55,9.87,9.16,9.13,22.3,9.09,22.86Z" />
              <path class="cls-1"
                d="M828.88,125.3c-16.33,0-31.72-13.86-32.49-14.57l5.4-5.9-2.7,2.95,2.7-2.96c.19.17,19.2,17.26,33.91,11.18,1.93-.8,4.31-2.12,4.69-3.9.41-1.91-1.2-5.02-4.31-8.3-1.67-1.77-3.17-2.94-3.19-2.96l4.91-6.32c.08.06,1.97,1.53,4.09,3.78,5.14,5.43,7.33,10.78,6.33,15.47-.89,4.16-4.07,7.4-9.45,9.62-3.25,1.35-6.58,1.91-9.87,1.91Z" />
              <path class="cls-1"
                d="M794.04,132.02l-.61-7.98.31,3.99-.32-3.99c.12,0,11.81-1.07,11.81-8.52h8c0,11.88-12.55,15.99-19.19,16.5Z" />
              <path class="cls-1"
                d="M801.53,157.12l-7.26-3.36,3.63,1.68-3.63-1.67c.06-.14,6.31-14.08.53-24.18l6.94-3.98c7.85,13.72.13,30.78-.2,31.5Z" />
              <path class="cls-1"
                d="M804.56,207.44l-6.17-5.09c17.23-20.89-7.41-49.34-7.66-49.62l6-5.3c10.26,11.6,25.11,39.08,7.83,60.01Z" />
              <path class="cls-1"
                d="M799.23,258.05l-5.05-6.2c22.3-18.15-.29-48.61-1.26-49.9l6.38-4.83c.29.38,7.03,9.35,10.55,21.16,4.88,16.39,1.21,30.14-10.62,39.77Z" />
              <path class="cls-1"
                d="M802.09,305.56l-7.19-3.52,3.59,1.76-3.59-1.75c.13-.27,12.82-26.85,2.15-48.76l7.19-3.5c12.4,25.46-1.55,54.55-2.15,55.78Z" />
              <path class="cls-1"
                d="M805.21,353.35l-6.28-4.96c15.64-19.82-6.87-47.73-7.1-48.01l6.18-5.08c1.11,1.34,26.85,33.16,7.2,58.05Z" />
              <path class="cls-1"
                d="M784.17,383.08c-5.43,0-14.53-1.16-17.91-8.48l7.26-3.35c1.78,3.85,9.72,3.98,12.94,3.74,16.25-10.35,8.34-33.26,8.26-33.5l7.54-2.68c3.68,10.35,5.81,32.77-12.47,43.52l-.71.42-.82.1c-.49.06-2.02.23-4.1.23Z" />
              <path class="cls-1"
                d="M772.11,317.51h-8c0-3.01.33-18.3,6.91-23.42l4.91,6.31c-2.49,1.94-3.82,11.18-3.82,17.1Z" />
              <path class="cls-1"
                d="M775.17,303.38c-.54-.42-13.17-10.5-14.34-24.82-.64-7.84,2.22-15.29,8.5-22.14l5.9,5.41c-4.7,5.13-6.87,10.53-6.43,16.04.86,10.82,11.22,19.15,11.32,19.23l-4.95,6.29Z" />
              <path class="cls-1"
                d="M773.23,266.85c-.23-.32-23.16-32.23,2.15-58.19l5.73,5.58c-20.54,21.07-2.21,46.79-1.42,47.87l-6.45,4.73Z" />
              <path class="cls-1"
                d="M770.44,218.86c-12.24-21.07-6.37-44.74-1.78-56.97,1.91-5.09,3.61-8.23,3.68-8.36l7.03,3.82s-1.52,2.82-3.22,7.34c-4.09,10.91-9.38,31.93,1.21,50.14l-6.92,4.02Z" />
              <path class="cls-1"
                d="M770.36,165.11c-.86-.69-20.83-17.42-.41-55.07l7.03,3.81c-17.07,31.48-2.21,44.52-1.57,45.05l-5.05,6.2Z" />
              <path class="cls-1"
                d="M771.69,116.22c-4.43,0-9.09-1.23-13.31-4.79l5.15-6.12c8.8,7.41,22.4-1.91,22.54-2l4.6,6.55c-.52.37-9.21,6.36-18.98,6.36Z" />
              <path class="cls-1"
                d="M757.18,111.7c-3.63,0-8.08-.84-13.38-3.26l3.33-7.27c11.27,5.16,16.31.85,16.36.81l5.66,5.66c-.24.24-4.2,4.07-11.97,4.07Z" />
            </g>
            <g id="E-3" data-name="E">
              <path class="cls-1"
                d="M843.64,103.27l-6.89-4.07c.19-.33,4.83-8.08,14.11-9.99,6.93-1.43,14.37.73,22.1,6.41l-4.74,6.45c-5.79-4.26-11.06-5.95-15.66-5.04-5.78,1.15-8.9,6.19-8.93,6.24Z" />
              <path class="cls-1"
                d="M882.51,130.8l-1.2-7.91c3.99-.62,12.8-3.24,14.48-7.37.74-1.81,0-4.21-2.19-7.13-10.32-13.77-29.27-3.75-29.46-3.65l-3.8-7.04c8.67-4.69,28.13-9.49,39.66,5.89,4.98,6.64,4.48,11.8,3.19,14.96-3.86,9.43-18.97,11.99-20.68,12.25Z" />
              <path class="cls-1"
                d="M865.56,140.22c-6.31,0-13.78-2.07-22.33-6.2l3.48-7.2c11.5,5.56,20.43,6.86,25.83,3.76,5.37-3.08,5.97-9.92,5.97-9.98l7.98.56c-.03.45-.84,11.05-9.87,16.31-3.16,1.84-6.86,2.76-11.07,2.76Z" />
              <path class="cls-1"
                d="M849.97,179.92l-5.24-6.05,2.62,3.02-2.63-3.02c.79-.69,19.18-17.22,3.68-38.2l6.43-4.75c15.96,21.6,3.79,41.49-4.87,49Z" />
              <path class="cls-1"
                d="M845.15,207.37l-5.13-6.14s2.89-2.46,5.45-6.34c4.41-6.7,5.14-13.21,2.16-19.34l7.2-3.49c4.23,8.72,3.3,18.14-2.68,27.23-3.24,4.92-6.85,7.95-7,8.08Z" />
              <path class="cls-1"
                d="M876.86,222.01l-4.19-6.81c1.72-1.07,6.4-4.85,7.28-9.27.46-2.32-.16-4.63-1.88-7.05-6.65-9.31-27.12,1.6-27.32,1.72l-3.87-7c1.09-.6,26.82-14.6,37.7.63,3.05,4.27,4.13,8.75,3.21,13.31-1.77,8.75-10.55,14.24-10.92,14.47Z" />
              <path class="cls-1"
                d="M849.74,242.26v-8c5.78,0,21.96-1.57,27.24-8.61,1.78-2.38,2.14-5.29,1.09-8.92l7.68-2.22c1.75,6.04.92,11.55-2.39,15.95-8.62,11.47-31.1,11.8-33.63,11.8Z" />
              <path class="cls-1"
                d="M845.9,273.28l-6.63-4.48c.96-1.42,1.83-2.89,2.59-4.37,8.32-16.24,1.6-30.34,1.31-30.94l7.16-3.56c.37.73,8.87,18.2-1.35,38.15-.9,1.77-1.94,3.52-3.09,5.21Z" />
              <path class="cls-1"
                d="M845.88,307.87l-6.58-4.55c15.01-21.74,3.77-33.76,3.29-34.26l5.66-5.65c.69.68,16.6,17-2.36,44.46Z" />
              <path class="cls-1"
                d="M836.07,322.8l-7.23-3.42c1.2-2.52,11.94-24.34,25.8-24.34.22,0,.44,0,.66.02,11.3.56,20.06,2.2,21.65,17.28l-7.96.84c-.87-8.23-2.71-9.56-14.1-10.13-6.97-.33-15.7,13.16-18.83,19.76Z" />
              <path class="cls-1"
                d="M860.03,359.04l-.33-7.99c1.07-.05,26.31-1.53,28.95-28.48.06-1.36-.05-11.47-16.49-12.38l.44-7.99c8.65.48,15.17,3.16,19.37,7.95,5.05,5.77,4.71,12.3,4.66,13.03-3.28,34.14-36.27,35.85-36.6,35.87Z" />
              <path class="cls-1"
                d="M834.87,373.98l-.56-.08c-7.5-1-21.23-7.8-19.54-26.37l7.97.72c-1.29,14.14,9.58,17.13,12.13,17.63,20.32-3.3,23.45-16.35,23.48-16.48l7.81,1.74c-.17.77-4.47,18.81-30.72,22.75l-.56.08Z" />
              <path class="cls-1"
                d="M820.79,353.2c-.49-.46-12.03-11.43-12.15-28.1-.09-11.89,5.59-23.44,16.87-34.3l5.55,5.76c-9.61,9.25-14.46,18.81-14.42,28.39.05,13.21,9.53,22.33,9.62,22.42l-5.47,5.83Z" />
              <path class="cls-1"
                d="M820.74,300.73c-18.85-18.17-.19-47.33.62-48.56l6.7,4.37-3.35-2.19,3.35,2.18c-.16.25-15.85,24.85-1.77,38.44l-5.55,5.76Z" />
              <path class="cls-1"
                d="M823.2,261.38c-12.03-17.5-3.19-31.82,3.17-36.96l5.04,6.21c-1.28,1.06-12.21,10.81-1.62,26.22l-6.59,4.53Z" />
              <path class="cls-1"
                d="M821.07,233.36c-20.66-45.71,4.72-68.11,5.81-69.04l5.19,6.08-2.6-3.04,2.61,3.03c-.9.79-21.81,19.68-3.74,59.67l-7.29,3.29Z" />
              <path class="cls-1"
                d="M821.12,173.88c-14.62-29.87,2.64-53.24,3.38-54.22l6.39,4.82c-.62.83-14.98,20.55-2.58,45.89l-7.19,3.52Z" />
            </g>
            <g id="R-2" data-name="R">
              <path class="cls-1"
                d="M932.62,126.37c-2.43-4.48-5.69-7.26-9.95-8.51-9.79-2.86-21.42,3.48-21.53,3.54l-3.89-6.99c.59-.33,14.6-8.02,27.62-4.25,6.35,1.84,11.32,6.01,14.78,12.38l-7.03,3.82Z" />
              <path class="cls-1"
                d="M966.56,165.51l-7.22-3.44,3.61,1.72-3.61-1.71c.07-.14,6.69-14.45.63-25-3.97-6.92-12.71-11.03-25.97-12.2l.71-7.97c16.05,1.42,26.9,6.89,32.23,16.24,8.11,14.21-.03,31.63-.38,32.37Z" />
              <path class="cls-1"
                d="M961.15,206.47l-1.19-7.91.59,3.96-.6-3.95c.1-.02,10.12-1.64,14.18-7.9,1.83-2.82,2.17-6.2,1.04-10.34-4.3-15.78-11.47-16.12-11.55-16.12.1,0,.15,0,.15,0l-.47-7.99c1.37-.08,13.54-.15,19.59,22.01,1.74,6.39,1.05,12.05-2.07,16.83-6.05,9.27-19.11,11.33-19.67,11.41Z" />
              <path class="cls-1"
                d="M930.27,227.97c-.44,0-.89,0-1.34,0l.11-8c12.97.19,21.72-2.54,25.31-7.88,3.98-5.93.78-13.88.75-13.96l7.36-3.13c.21.48,4.97,11.97-1.47,21.55-5.1,7.59-15.43,11.43-30.72,11.43Z" />
              <path class="cls-1"
                d="M949.42,243.12l-5.12-6.15,2.56,3.07-2.59-3.05s2.66-2.33,2.74-5.33c.05-1.96-1.03-4.01-3.22-6.11l5.54-5.77c3.91,3.76,5.82,7.86,5.67,12.21-.23,6.49-5.03,10.67-5.58,11.13Z" />
              <path class="cls-1"
                d="M958.23,279.28l-7.25-3.39c1-2.15,1.78-4.28,2.31-6.34,5.11-20.05-14.63-30.62-14.83-30.72l3.7-7.09c8.86,4.62,24.21,18.89,18.88,39.79-.65,2.55-1.6,5.16-2.81,7.76Z" />
              <path class="cls-1"
                d="M957.62,326.5c-3.02,0-5.28-.9-6.43-1.48-6.64-3.37-9.88-11.47-7.56-18.84l7.63,2.41c-1.12,3.56.47,7.73,3.55,9.29,4.14,2.1,9.76-.93,15.03-8.1,2.79-3.79,3.79-7.74,3.06-12.09-1.76-10.54-13.61-20.8-18.03-23.86l4.56-6.58c.76.53,18.69,13.12,21.37,29.12,1.08,6.5-.43,12.6-4.51,18.14-7.11,9.66-13.85,11.98-18.67,11.98Z" />
              <path class="cls-1"
                d="M945.24,317.59c-23.06-10.19-18.86-38.61-13.42-52.21l7.43,2.97c-.52,1.31-12.54,32.31,9.22,41.93l-3.23,7.32Z" />
              <path class="cls-1"
                d="M930.69,277.81c-16.55-12.1-10.6-32.5-5.14-41.6l6.86,4.11c-.49.82-11.78,20.23,3,31.03l-4.72,6.46Z" />
              <path class="cls-1"
                d="M912.05,273.25l-6.66-4.44c8.32-12.48,6.33-23.54,3.21-30.62-1.88-4.25-4.07-6.82-4.09-6.84l6.03-5.26c.12.14,2.98,3.43,5.38,8.87,3.22,7.3,7.01,21.98-3.87,38.29Z" />
              <path class="cls-1"
                d="M922.51,245.36c-11.54,0-13.85-6.81-14.1-7.69l7.7-2.18s-.01-.1-.09-.26c.02.03,1.62,3.2,10.86,1.76l1.23,7.91c-2.07.32-3.93.46-5.6.46Z" />
              <path class="cls-1"
                d="M908.28,310.46l-6.26-4.97c13.95-17.57,1.04-36.11.48-36.89l6.49-4.67c5.97,8.27,13.51,28.64-.71,46.54Z" />
              <path class="cls-1"
                d="M897.88,350.86c-5.66,0-10.68-1.79-13.86-3.7l4.12-6.86-2.06,3.43,2.04-3.44c1.52.89,15.12,8.26,25.68-6.62,2.9-4.08,3.9-7.96,3.06-11.86-1.78-8.26-11.25-13.89-11.35-13.95l4.02-6.92c.51.3,12.59,7.4,15.14,19.13,1.34,6.14-.13,12.27-4.35,18.22-6.8,9.58-15.15,12.56-22.44,12.56Z" />
              <path class="cls-1"
                d="M874.97,307.34c-.21-1.51-4.97-37.09,14.3-45.35l3.15,7.35c-11.35,4.86-10.73,28.27-9.53,36.88l-7.92,1.11Z" />
              <path class="cls-1"
                d="M884.29,269.53c-16.08-18.01-11.83-37.86-11.64-38.7l7.81,1.75c-.15.68-3.45,16.78,9.8,31.63l-5.97,5.33Z" />
              <path class="cls-1"
                d="M945.7,195.02c-7.26-5.28-11.28-11.04-11.94-17.13-.71-6.54,2.67-11.45,4.75-13.79.84-.95,1.49-1.5,1.62-1.6l5.12,6.15-2.56-3.07,2.58,3.05s-.33.28-.78.79c-1.2,1.35-3.16,4.14-2.78,7.62.4,3.69,3.4,7.68,8.69,11.52l-4.71,6.47Z" />
              <path class="cls-1"
                d="M935.66,172.17c-4.58,0-10.2-1.75-15.55-7.43l5.83-5.48c6.96,7.39,12.9,4.41,13.56,4.04l4.06,6.89c-1.72,1.03-4.53,1.98-7.9,1.98Z" />
              <path class="cls-1"
                d="M914.46,177.35c-1.77,0-3.89-.43-6.33-1.65l3.58-7.16c.88.44,2.51,1.11,3.57.66.99-.43,1.54-1.82,1.55-1.83l.11-.31c1.59-3.98,1.84-7.02.68-8.55-1.61-2.14-6.24-2.18-7.3-2.07l-.8-7.96c1-.1,9.95-.81,14.49,5.22,2.97,3.94,3.1,9.4.39,16.22-.35,1.02-1.93,4.9-5.98,6.63-1.01.43-2.33.79-3.96.79Z" />
              <path class="cls-1"
                d="M925.01,207.41l-7.55-2.65,3.77,1.32-3.78-1.32c.07-.2,6.92-20.4-4.46-27.8l4.36-6.71c16.64,10.82,8.03,36.08,7.65,37.15Z" />
              <path class="cls-1"
                d="M927.24,196.2l-6.05-5.24c9.28-10.71,22.66-7.25,23.23-7.1l-2.08,7.72,1.04-3.86-1.02,3.87c-.37-.1-9.18-2.25-15.12,4.61Z" />
              <path class="cls-1"
                d="M871.46,191.51c-2.78-11.81,5.12-22.53,5.46-22.98l6.4,4.8-3.2-2.4,3.21,2.39c-.06.08-5.99,8.23-4.08,16.36l-7.79,1.83Z" />
              <path class="cls-1"
                d="M886.64,177.86c-.67-.11-16.52-2.76-22.52-13.87-3.15-5.84-3-12.68.44-20.32.25-.57,6.65-15.06,18.54-21l3.58,7.16c-9.26,4.63-14.75,16.97-14.81,17.09-2.42,5.37-2.64,9.7-.71,13.28,4.07,7.55,16.61,9.75,16.74,9.77l-1.26,7.9Z" />
            </g>
          </g>
        </svg>
  
      </div>
  
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isLoading: true // Initialer Ladezustand
      };
    },
    mounted() {
      // Nach einer gewissen Zeit wird der Ladebildschirm beendet (simuliert)
      setTimeout(() => {
        this.isLoading = false;
        // Navigiere zur Startseite der Home-Ansicht
        this.navigateToHome();
      }, 3500); // Hier kannst du die Zeit für die Animation anpassen
  
    },
    methods: {
      navigateToHome() {
        // Hier die Navigationslogik zur Home-Ansicht einfügen
        this.$router.push('/home');
      }
    }
  };
  </script>
  
  <style scoped>
  .loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    /* Hintergrundfarbe mit Transparenz */
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  
  .buzzer {
    width: 40%;
    height: 60%;
  }
  
  @media (max-width: 768px) {
    /* Stile für Bildschirme mit einer Breite von maximal 768px */
    .buzzer {
    width: 80%;
    height: auto;
    }
  }
  /* CSS für die SVG-CSS-Animation */
  
  .loading-logo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 2s forwards;
  }
  
  #B {
    animation: meineAnimation 2s forwards;
    ;
    animation-delay: 0.1s;
    /* Verzögerung von 1 Sekunde */
  }
  
  #U {
    animation: meineAnimation 2s forwards;
    ;
    animation-delay: 0.2s;
  }
  
  #Z {
    animation: meineAnimation 2s forwards;
    ;
    animation-delay: 0.3s;
  }
  
  #Z-2 {
    animation: meineAnimation 2s forwards;
    ;
    animation-delay: 0.4s;
  }
  
  #E {
    animation: meineAnimation 2s forwards;
    ;
    animation-delay: 0.5s;
  }
  
  #R {
    animation: meineAnimation 2s forwards;
    ;
    animation-delay: 0.6s;
  }
  
  #B-2 {
    animation: meineAnimation 2s forwards;
    ;
    animation-delay: 0.7s;
  }
  
  #E-2 {
    animation: meineAnimation 2s forwards;
    ;
    animation-delay: 0.8s;
  }
  
  #A {
    animation: meineAnimation 2s forwards;
    ;
    animation-delay: 0.9s;
  }
  
  #T {
    animation: meineAnimation 2s forwards;
    ;
    animation-delay: 1s;
  }
  
  #E-3 {
    animation: meineAnimation 2s forwards;
    ;
    animation-delay: 1.2s;
  }
  
  #R-2 {
    animation: meineAnimation 2s forwards;
    ;
    animation-delay: 1.3s;
  }
  
  @keyframes meineAnimation {
    0% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.5;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes clear {
    0% {
      opacity: 1;
    }
  
    50% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  </style>