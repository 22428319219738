<template>
    <button @click="scrollToNextSection" class="">{{ buttonText }}</button>
</template>

<script>
export default {
    props: {
        buttonText: {
            type: String,
            required: true
        },
        targetSectionId: {
            type: String,
            required: true
        }
    },
    methods: {
        scrollToNextSection() {
            const targetSection = document.getElementById(this.targetSectionId);
            if (targetSection) {
                const targetOffset = targetSection.offsetTop;
                window.scrollTo({
                    top: targetOffset,
                    behavior: 'smooth'
                });
            } else {
                console.error('Target section not found.');
            }
        }
    }
}
</script>

<style scoped>
/* .button:hover {

    color: #e0e1dd;

}


.buttonText {
    transition: font-weight 0.3s ease;
    
} */
</style>