<template>
  <!--   <nav class="navbar">


    <AboutButton buttonText="Start" targetSectionId="start" />
    <AboutButton buttonText="About" targetSectionId="about" />
    <AboutButton buttonText="Events" targetSectionId="events" />
    <AboutButton buttonText="Booking" targetSectionId="booking" />

  </nav> -->

  <div class="flex flex-col h-full w-full bg-custom-lightgray" ref="wrapper">


    <NavBar />

    <!-- <img src="../assets/NBA_RedBlue_Logo.png" class="fixed left-5 top-5 z-30 m-0 w-10" /> -->

    <!-- first Section -->
    <section id="start" class="relative flex min-h-svh flex-col md:h-screen">



      <div class="w-full flex justify-center">
        <img src="../assets/NBA_LOGOWhite.svg" class="absolute animate-spin top-44 z-10 w-6 md:w-8 lg:w-8 xl:w-12" />
      </div>

      <img src="../assets/Buzzerbeater_LogoWhite.svg"
        class="absolute left-1/2 top-60 z-10 w-2/3 -translate-x-1/2 transform md-w-3/4 lg:w-2/3 xl:w-1/3 xl:top-80" />



      <img alt="Berlin_DJ_Techno" src="../assets/DJ_Pic4.jpg"
        class="h-4/6 w-full object-cover pl-8 pr-8 pt-20 lg:pl-16 lg:pr-16 lg:pt-16" />

      <div class="flex h-1/3 w-full items-center justify-center rounded-lg bg-custom-main p-10 text-white">
        <h1 class="w-full max-w-2xl text-center font-sans text-sm font-bold lg:text-lg ">
          Joan Leininger aka BuzzerBeater, is a 23 year old music
          producer and DJ with a passion and diversity for electronic
          music, in the heart of Berlin's vibrant music scene. With a
          background in hip-hop, trance and house music, he is well
          versed in several genres and tests himself through a wide
          variety of music styles without losing the current trend.
        </h1>
      </div>


    </section>

    <!-- second section -->
    <section id="about" class="relative flex h-svh min-h-640 flex-col md:mi-h-screen">

      <div class="flex h-full w-full justify-between">

        <img src="../assets/DJ_Pic2.jpg" class="h-full w-2/4 lg:w-2/6 xl:w-1/6 object-cover" alt="Berlin_DJ_Techno" />

        <!-- Spotify/Insta/SoundCloud-Link-List -->


        <ul class="flex flex-col justify-center items-start mr-4 h-full sm:w-2/4 ">
          <li class="mb-2 flex items-center">
            <img src="../assets/SpotifyIcon.svg" class="mx-2 h-8 w-8 md:w-12 md:h-12" alt="Spotify Logo" />
            <a class="text-white underline md:text-2xl"
              href="https://open.spotify.com/intl-de/artist/4PYEDmtzEFziPyk0vaaDxG?si=6_ojKfVYSU2SDdAK78NQfQ"
              target="_blank">BuzzerBeater</a>
          </li>

          <li class="mb-2 flex items-center">
            <img src="../assets/InstaIcon.svg" class="mx-2 h-8 w-8 md:w-12 md:h-12" alt="Instagram Logo" />
            <a class="text-white underline md:text-2xl"
              href="https://www.instagram.com/prod.buzzerbeater?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="_blank">prod.buzzerbeater</a>
          </li>

          <li class="mb-2 flex items-center">
            <img src="../assets/SoundCloudIcon.svg" class="mx-2 h-8 w-8 md:w-12 md:h-12" alt="SoundCloud Logo" />
            <a class="text-white underline md:text-2xl" href="https://on.soundcloud.com/k6oF8"
              target="_blank">BuzzerBeater</a>
          </li>
        </ul>
      </div>

      <div
        class="flex h-full w-full flex-col items-center justify-center rounded-lg bg-black p-10 text-white md:p-12 lg:p-14">
        <h2 class="mb-6 w-full max-w-2xl text-center font-sans text-3xl font-bold md:mb-12 lg:mb-16 lg:text-5xl">
          About
        </h2>

        <h2 class="w-full max-w-2xl text-center font-sans text-xs font-bold sm:text-sm lg:text-lg">
          His journey through the clubs and underground scenes of
          Berlin have not only given him unique experiences, but also
          shaped his unique style that blends the boundaries between
          genres. Off stage, BuzzerBeater is an avid music creator,
          constantly working on new tracks and remixes to express his
          artistic vision. His studio in Berlin Schöneberg is his
          creative safe space, where he works day and night on new
          sounds and ideas to push the boundaries of music.
          BuzzerBeater is not only a musician, but also a storyteller
          who shares his passion for music with the world, always
          looking for new ways to move and inspire people.
        </h2>
      </div>
    </section>

    <!-- third section -->
    <section id="events" class="relative flex h-svh flex-col md:min-h-screen">

      <div class="flex w-full justify-between">



        <div class="flex h-60 flex-col h-full w-full items-center justify-center  p-10 text-white">
          <h2 class="mb-2 max-w-2xl text-center font-sans text-3xl font-bold md:mb-8 lg:mb-8 lg:text-5xl ">
            Events
          </h2>

          <h1 class="w-full text-center font-sans text-sm font-bold md:text-lg lg:text-xl">
            21 JUNI: Secret Location Berlin
          </h1>

          <h1 class="mt-2 w-full text-center font-sans text-sm font-bold md:text-lg lg:text-xl">
            27 JUNI: Campus Run 2024 FU Berlin
          </h1>
        </div>

        <img src="../assets/DJ_Pic3.jpg" class="h-full w-2/5 md:w-4/6 xl:w-1/6 " alt="Berlin_DJ_Techno" />

      </div>


      <div
        class="flex h-full w-full flex-col items-center justify-center rounded-lg bg-black p-4 text-white md:p-10 lg:p-12">
        <h2 class="mb-4 w-full max-w-2xl text-center font-sans text-3xl font-bold md:mb-12 lg:mb-16 lg:text-5xl">
          Experience
        </h2>

        <h2 class="w-4/5 max-w-2xl text-center font-sans text-xs font-bold sm:text-sm lg:text-lg">
          Experience the real Berlin music scene with BuzzerBeater!
          His talent for seamlessly combining different music genres
          creates a captivating atmosphere. BuzzerBeater has already
          proven his outstanding skills at numerous events and has
          built up a small fan base. If you want to be part of this
          unique experience, then secure your tickets now and immerse
          yourself in the world of BuzzerBeater, where beats become a
          feeling!
        </h2>
      </div>
    </section>

    <!-- fourth section -->
    <section id="booking" class="relative mb-16 lg:mb-0 flex min-h-svh min-h-640 flex-col md:min-h-screen">

      <div class="h-36 rounded-lg bg-custom-main p-10"></div>

      <div class="pl-2 pr-2 ">
        <div
          class="flex mb-2 h-40 h-full w-full flex-col items-center justify-center rounded-lg bg-black text-white md:p-10 lg:p-12">
          <h2 class="mt-4 w-full max-w-2xl text-center font-sans text-3xl font-bold md:mb-4 lg:mb-8 lg:text-4xl">
            Booking
          </h2>

          <ContactForm />
        </div>

      </div>

      <div class="mt-4">
        <ImpressumBar />
      </div>

    </section>

    <!-- Impressum -->



  </div>
</template>

<script>
import ContactForm from '../components/ContactForm.vue';
import ImpressumBar from '../components/ImpressumBar.vue';
import NavBar from '../components/NavBar.vue';
import AboutButton from '../components/AboutButton.vue';
import StartButton from '../components/StartButton.vue';
import EventsButton from '../components/EventsButton.vue';
import BookingButton from '../components/BookingButton.vue';



export default {
  name: 'HomeView',
  components: {
    ContactForm,
    ImpressumBar,
    NavBar,
    AboutButton,
    BookingButton,
    EventsButton,
    StartButton,

  },

  mounted() {
    // Add scroll and resize event listeners to the window
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
  },
  beforeUnmount() {
    // Remove scroll and resize event listeners
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);
  },
  methods: {
    handleScroll() {
      // Adjust the background color of the wrapper based on scroll position
      const scrollPosition = window.scrollY;

      // Define color change thresholds
      let thresholds, colors;
      if (window.innerWidth <= 768) {
        thresholds = [0, 400, 1000, 1900]; // Adjust thresholds for small screens
        colors = ['#4D4D4D', '#64656B', '#4D4D4D', '#64656B']; //WEIß:e0e1dd Adjust colors for small screens
      } else {
        thresholds = [0, 600, 1900, 3600]; // Default thresholds for larger screens
        colors = ['#4D4D4D', '#64656B', '#4D4D4D', '#64656B']; // Default colors for larger screens
      }

      // Determine the appropriate color based on scroll position
      let backgroundColor = colors[0]; // Default color
      for (let i = 1; i < thresholds.length; i++) {
        if (scrollPosition >= thresholds[i]) {
          backgroundColor = colors[i];
        }
      }

      // Update the background color of the wrapper
      this.$refs.wrapper.style.transition = 'background-color 0.5s ease';
      this.$refs.wrapper.style.backgroundColor = backgroundColor;
    },
  },
};
</script>

<style></style>