<template>
    <button @click="goBack" class="button">{{ buttonText }}</button>
</template>

<script>
export default {
    props: {
        buttonText: {
            type: String,
            required: true
        }
    },
    methods: {
        goBack() {

            this.$router.go(-1);
        }
    }
}
</script>

<style scoped>
/* .button {
    background-color: transparent;
    border: none;
    cursor: pointer;

    transition: background-color 0.3s ease;
    font-family: 'Bree', sans-serif;
}

.button:hover {

    border-radius: 5px;
} */
</style>
