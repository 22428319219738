<template>

    <div class="w-full flex justify-center items-center">

        <div>
            <ImpressumButton buttonText="Impressum / Datenschutz" />
            <hr>
            <p class="text-center"> c 2024 Julian Ehlers Events, Booking & Management</p>
            <p class="text-center text-xs"> created by Vincent Soltmann</p>
        </div>

    </div>
</template>

<script>

import ImpressumButton from "./ImpressumButton.vue";

export default {
    components: {

        ImpressumButton,
    }
}
</script>

<style scoped>
.button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 28px;
    line-height: 30px;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    font-weight: light;
    color: #000000;
    width: 100%;
    padding-left: 35px;
}

@media (max-width: 768px) {

    /* Stile für Bildschirme mit einer Breite von maximal 768px */
    .button {
        margin-right: 0;
        margin-left: 0;
        font-size: 20px;
        /* Ändern Sie die Breite auf 90% des Wrappers */
    }
}

/* Style for button hover effect */
.button:hover {
    background-color: #64656B;
    color: #ffffff;
    border-radius: 5px;
}

.button:hover .buttonText {
    font-weight: bold;
}

.buttonText {
    transition: font-weight 0.3s ease;
    /* Add transition effect */
}
</style>