<template>

    <nav class="fixed top-10 w-full h-16 bg-white rounded-md shadow-4xl z-20 flex items-center justify-center">

        <div
            class="flex w-full max-w-screen-lg justify-around items-center font-bold text-base md:text-2xl lg:text-3xl px-6 md:px-12">


            <div class="w-10 items-start z-30">
                <img @click.prevent="refresh" src="../assets/NBA_RedBlue_Logo.png" />
            </div>

            <div class="button-container">
                <AboutButton buttonText="Start" targetSectionId="start" />
            </div>
            <div class="button-container">
                <AboutButton buttonText="About" targetSectionId="about" />
            </div>
            <div class="button-container">
                <EventsButton buttonText="Events" targetSectionId="events" />
            </div>
            <div class="button-container">
                <BookingButton buttonText="Booking" targetSectionId="booking" />
            </div>
        </div>

    </nav>
</template>

<script>
import StartButton from './StartButton.vue';
import AboutButton from './AboutButton.vue';
import EventsButton from './EventsButton.vue';
import BookingButton from './BookingButton.vue';


export default {
    components: {

        AboutButton,
        EventsButton,
        BookingButton,
        StartButton,

    },
    methods: {
        refresh() {
            this.$router.push('/');
        },

    }
}
</script>

<style scoped>
.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    /* Adjust padding as needed */
    transition: background-color 0.3s ease;
}

.button-container:hover {
    background-color: #4D4D4D;
    color: white;
    border-radius: 5px;
}

.button-container:hover .button-text {
    font-weight: bold;
    color: #ffffff;
    /* Change text color on hover */
}
</style>
