<template>
    <nav class="navbar">

        <UpButton buttonText="Up" />
        <div class="BZBLogo">
            <img src="../assets/BZB_Basketball_Logo.svg">
        </div>
        <BackButton buttonText="Back" />

    </nav>

    <div class="impressum-screen">




        <div class="BZBLogoBack">
            <img src="../assets/BZB_Logo_Back.svg">
        </div>



        <div class="background">
            <p class="header mt110">Angaben gem. § 5 TMG:</p>
            <p class="header">Impressum</p>
            <p class="address mt60">Julian Ehlers Events, Booking & Management</p>
            <p class="address mt60">Hansastraße 15a

            </p>
            <p class="mail">13409 Berlin</p>
            <p class="mail">Germany</p>
            <p class="address mt60">Kontakt:</p>
            <p class="address">julian@prod-buzzerbeater.de</p>
            <p class="address">+49 171 52 66337</p>

            <p class="header">Haftungsausschluss – Disclaimer:</p>
            <p class="text">Alle Inhalte unseres Internetauftritts wurden mit größter
                Sorgfalt und nach bestem Gewissen erstellt. Für die Richtigkeit,
                Vollständigkeit und Aktualität der Inhalte können wir jedoch
                keine Gewähr übernehmen. Als Diensteanbieter sind wir
                gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
                nach den allgemeinen Gesetzen verantwortlich.
                Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
                nicht verpflichtet, übermittelte oder gespeicherte fremde
                Informationen zu überwachen oder nach Umständen zu forschen,
                die auf eine rechtswidrige Tätigkeit hinweisen.
                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                Informationen nach den allgemeinen Gesetzen bleiben hiervon
                unberührt.</p>
            <p class="text2">Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                Kenntniserlangung einer konkreten Rechtsverletzung möglich.
                Bei Bekanntwerden von den o.g. Rechtsverletzungen werden wir
                diese Inhalte unverzüglich entfernen.</p>

            <p class="header">Haftungsbeschränkung für externe Links</p>
            <p class="text">Unsere Webseite enthält Links auf externe Webseiten Dritter.
                Auf die Inhalte dieser direkt oder indirekt verlinkten
                Webseiten haben wir keinen Einfluss. Daher können wir für die
                „externen Links“ auch keine Gewähr auf Richtigkeit der
                Inhalte übernehmen. Für die Inhalte der externen Links sind
                die jeweiligen Anbieter oder Betreiber (Urheber) der Seiten
                verantwortlich.</p>
            <p class="text2">Die externen Links wurden zum Zeitpunkt der Linksetzung auf eventuelle Rechtsverstöße
                überprüft
                und waren im Zeitpunkt der Linksetzung frei von rechtswidrigen Inhalten. Eine ständige inhaltliche
                Überprüfung
                der externen Links ist ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht möglich. Bei direkten
                oder
                indirekten Verlinkungen auf die Webseiten Dritter, die außerhalb unseres Verantwortungsbereichs liegen,
                würde
                eine Haftungsverpflichtung ausschließlich in dem Fall nur bestehen, wenn wir von den Inhalten Kenntnis
                erlangen
                und es uns technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu
                verhindern.</p>
            <p class="text2">Diese Haftungsausschlusserklärung gilt auch innerhalb des eigenen Internetauftrittes
                „prod-buzzerbeater.de“ gesetzten Links und Verweise von Fragestellern, Blogeinträgern, Gästen des
                Diskussionsforums.
                Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung
                oder
                Nichtnutzung solcherart dargestellten Informationen entstehen, haftet allein der Diensteanbieter der
                Seite, auf
                welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich
                verweist.
            </p>
            <p class="text2">Werden uns Rechtsverletzungen bekannt, werden die externen Links durch uns unverzüglich
                entfernt.
            </p>

            <p class="header">Urheberrecht</p>
            <p class="text">Die auf unserer Webseite veröffentlichen Inhalte und Werke unterliegen dem deutschen
                Urheberrecht
                (http://www.gesetze-im-internet.de/bundesrecht/urhg/gesamt.pdf). Die Vervielfältigung, Bearbeitung,
                Verbreitung
                und jede Art der Verwertung des geistigen Eigentums in ideeller und materieller Sicht des Urhebers
                außerhalb der
                Grenzen des Urheberrechtes bedürfen der vorherigen schriftlichen Zustimmung des jeweiligen Urhebers
                i.S.d.
                Urhebergesetzes (http://www.gesetze-im-internet.de/bundesrecht/urhg/gesamt.pdf). Downloads und Kopien
                dieser
                Seite sind nur für den privaten und nicht kommerziellen Gebrauch erlaubt. Sind die Inhalte auf unserer
                Webseite
                nicht von uns erstellt wurden, sind die Urheberrechte Dritter zu beachten. Die Inhalte Dritter werden
                als solche
                kenntlich gemacht. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir
                um einen
                entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
                unverzüglich
                entfernen.</p>

            <p class="header">Datenschutzerklärung nach DSGVO</p>
            <p class="text">Die Nutzung dieser Webseite kann mit der Verarbeitung von personenbezogenen Daten verbunden
                sein.
                Damit diese Verarbeitungen für Sie nachvollziehbar sind, möchten wir Ihnen mit den folgenden
                Informationen einen
                Überblick zu diesen Verarbeitungen verschaffen. Um eine faire Verarbeitung zu gewährleisten, möchten wir
                Sie
                außerdem über Ihre Rechte nach der Europäischen Datenschutz-Grundverordnung (DSGVO) und dem
                Bundesdatenschutzgesetz (BDSG) informieren.</p>

            <p class="text">Allgemeine Angaben zur Datenverarbeitung</p>
            <p class="text">Wir verarbeiten personenbezogene Daten unter Beachtung der einschlägigen
                Datenschutzvorschriften,
                insbesondere der DSGVO und des BDSG. Eine Datenverarbeitung durch uns findet nur auf der Grundlage einer
                gesetzlichen Erlaubnis statt. Bei der Nutzung dieser Website verarbeiten wir personenbezogene Daten nur
                mit
                Ihrer Einwilligung (Art. 6 Abs. 1 Buchst. a) DSGVO), zur Erfüllung eines Vertrags, dessen Vertragspartei
                Sie
                sind, oder auf Ihre Anfrage zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 Buchst. b)
                DSGVO), zur
                Erfüllung einer rechtlichen Verpflichtung (Art. 6 Abs. 1 Buchst. c) DSGVO) oder wenn die Verarbeitung zu
                Wahrung
                unser berechtigten Interessen oder den berechtigten Interessen eines Dritten erforderlich ist, sofern
                nicht Ihre
                Interessen oder Grundrechte und Grundfreiheiten, die den Schutz personenbezogener Daten erfordern,
                überwiegen
                (Art. 6 Abs. 1 Buchst. f) DSGVO).</p>

            <p class="text">Personenbezogene Daten</p>
            <p class="text">Wir erheben und verwenden deine personenbezogenen Daten nur, um die von dir gewünschten
                Dienste
                (z.B. bei einer Booking-Anfrage) zu ermöglichen und um sicherzustellen, dass dir unsere Webseite in
                möglichst
                effektiver, nachvollziehbarer und interessanter Weise präsentieren.</p>
            <p class="text2">Daten, die wir im Zuge der Nutzung unserer Webseiten erfahren, werden nur zu den
                angegebenen
                Zwecken verarbeitet. Das berechtigte Interesse zur Erhebung der Daten des Internet-Service-Providers
                ergibt sich
                aus dem Interesse, sich zukünftig vor Angriffen über das Internet besser zu schützen. Der Grundsatz
                der
                Datensparsamkeit und Datenvermeidung wird grundsätzlich von uns beachtet, in dem wir Pflichtfelder mit
                einem (*)
                kennzeichnen und optionale Zusatzangaben nicht verpflichtend angegeben werden müssen.</p>
            <p class="text2">Die Verarbeitung personenbezogener Daten erfolgt im Rahmen der jeweils geltenden
                Rechtsvorschriften (Art. 6 I 1 b) und f) EU DS-GVO).</p>

            <p class="text">Verarbeitung von Server-Log-Files</p>
            <p class="text">Bei der rein informativen Nutzung unserer Website werden zunächst automatisiert (also nicht
                über
                eine Registrierung oder Kontaktanfrage) allgemeine Informationen gespeichert, die Ihr Browser an unseren
                Server
                übermittelt. Hierzu zählen standardmäßig: Browsertyp/ -version, verwendetes Betriebssystem, aufgerufene
                Seite,
                die zuvor besuchte Seite (Referrer URL), IP-Adresse, Datum und Uhrzeit der Serveranfrage und
                HTTP-Statuscode.
                Die Verarbeitung erfolgt zur Wahrung unserer berechtigten Interessen und beruht auf der Rechtsgrundlage
                des Art.
                6 Abs. 1 Buchst. f) DSGVO. Diese Verarbeitung dient der technischen Verwaltung und der Sicherheit der
                Website.
                Die gespeicherten Daten werden gelöscht, wenn nicht aufgrund konkreter Anhaltspunkte ein berechtigter
                Verdacht
                auf eine rechtswidrige Nutzung besteht und eine weitere Prüfung und Verarbeitung der Informationen aus
                diesem
                Grund erforderlich ist.</p>

            <p class="text">Dauer der Speicherung</p>
            <p class="text">Sofern sich aus den folgenden Hinweisen nichts anderes ergibt, speichern wir die Daten nur
                so
                lange, wie es zur Erreichung des Verarbeitungszwecks oder für die Erfüllung unserer vertraglichen oder
                gesetzlichen Pflichten erforderlich ist. Solche gesetzlichen Aufbewahrungspflichten können sich
                insbesondere aus
                handels- oder steuerrechtlichen Vorschriften ergeben.</p>

            <p class="text">Instagram-, Youtube-, Soundcloud- und Spotify-Links</p>
            <p class="text">Auf unserer Webseite finden Sie Links zu den Social Media-Diensten von Instagram, Youtube,
                Soundcloud und Spotify. Links zu den Internetseiten der Social Media-Dienste erkennen Sie an dem
                jeweiligen
                Unternehmens-Logo. Wenn Sie diesen Links folgen, erreichen Sie den Auftritt von BuzzerBeater bei dem
                jeweiligen
                Social Media-Dienst. Beim Anklicken eines Links zu einem Social Media-Dienst wird eine Verbindung zu den
                Servern
                des Social Media-Dienstes hergestellt. Hierdurch wird an die Server des Social Media-Dienstes
                übermittelt, dass
                Sie unsere Webseite besucht haben. Darüber hinaus werden weitere Daten an den Anbieter des Social
                Media-Dienstes übertragen. Das sind beispielsweise die Adresse der Webseite, auf der sich der
                aktivierte Link
                befindet, Datum und Uhrzeit des Aufrufs der Webseite bzw. der Aktivierung des Links, Informationen über
                den
                verwendeten Browser sowie das verwendete Betriebssystem und die IP-Adresse.</p>
            <p class="text2">Wenn Sie zum Zeitpunkt der Aktivierung des Links bereits bei dem entsprechenden Social
                Media-Dienst eingeloggt sind, kann der Anbieter des Social Media-Dienstes in der Lage sein, aus den
                übermittelten Daten Ihren Benutzernamen und ggf. sogar Ihren echten Namen ermitteln und diese
                Informationen
                Ihrem persönlichen Benutzerkonto bei dem Social Media-Dienst zuordnen. Sie können diese Möglichkeit der
                Zuordnung zu Ihrem persönlichen Benutzerkonto ausschließen, wenn Sie sich vorher aus Ihrem Benutzerkonto
                des
                Social Media-Dienstes ausloggen. Die Server der Social Media-Dienste können sich in den USA und anderen
                Ländern
                außerhalb der Europäischen Union befinden. Die Daten können durch den Anbieter des Social Media-Dienstes
                daher
                auch in Ländern außerhalb der Europäischen Union verarbeitet werden. Bitte beachten Sie, dass
                Unternehmen in
                diesen Ländern einem Datenschutzgesetz unterliegen, das im Allgemeinen personenbezogene Daten nicht in
                demselben
                Maße schützt, wie es in den Mitgliedstaaten der Europäischen Union der Fall ist. Bitte beachten Sie,
                dass wir
                keinen Einfluss auf Umfang, Art und Zweck der Datenverarbeitung durch den Anbieter des Social
                Media-Dienstes
                haben. Nähere Informationen zur Verwendung Ihrer Daten durch die auf unserer Webseite eingebundenen
                Social
                Media-Dienste finden Sie in der Datenschutzrichtlinie des jeweiligen Social Media-Dienstes.</p>

            <p class="header">Nutzerrechte</p>
            <p class="text">Auf Antrag können wir Ihnen kostenlos per E-Mail oder schriftlich mitteilen, ob und welche
                personenbezogenen Daten über Sie bei uns gespeichert sind, woher wir diese Daten erhalten haben, an
                welche
                Empfänger oder Kategorien von Empfängern wir Ihre Daten weitergegeben haben und zu welchen Zwecken wir
                diese
                verwenden. Zudem können Sie von uns verlangen, dass wir Ihre Daten berichtigen, wenn diese nicht korrekt
                bei uns
                gespeichert sind, dass wir Ihre Daten löschen bzw. deren Verarbeitung einzuschränken, bzw. sonst der
                Verarbeitung zu widersprechen, Ihre Daten zu anonymisieren oder im Einklang mit dem Datenschutzrecht zu
                übertragen. Bitte senden Sie uns hierzu eine Anfrage an unsere allgemeine Mail-Adresse (siehe Kontakt).
                Sie
                haben das Recht, sich für Beanstandungen bei der zuständigen Aufsichtsbehörde für Datenschutz zu
                melden. Sie
                haben jederzeit das Recht, die erteilten Einwilligungen zur Verwendung personenbezogener Daten zu
                widerrufen.
                Näheres können Sie an den jeweiligen Ausführungen zu den einzelnen angebotenen Diensten in der
                Datenschutzerklärung nachlesen.</p>

            <p class="header">Vorbehalt</p>
            <p class="text">Buzzerbeater und das Management behalten sich das Recht vor, jederzeit ihre
                Datenschutzerklärung
                unter Beachtung der datenschutzrechtlichen Vorgaben zu ändern. Änderungen werden auf dieser Webseite
                rechtzeitig
                bekannt gegeben. Daher sollten Sie diese Seite regelmäßig aufrufen, um sich über den aktuellen Stand zu
                informieren.</p>


        </div>

    </div>
</template>

<script>
import BackButton from "../components/BackButton.vue";
import StartButton from "../components/StartButton.vue";
import UpButton from "../components/UpButton.vue";



export default {
    name: 'ImpressumView',
    components: {
        BackButton,
        StartButton,
        UpButton,
    },

}

</script>

<style>
.navbar {
    position: fixed;
    top: 3vh;
    /* Adjust as needed */
    left: 0;
    /* Adjust as needed */
    width: 100%;
    /* Adjust as needed */
    height: 60px;
    /* Adjust as needed */
    background-color: #4D4D4D;
    ;
    /* Your desired background color */
    border-radius: 5px;
    /* Optional: Add border-radius for rounded corners */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    z-index: 6;
}

@media (max-width: 768px) {

    /* Stile für Bildschirme mit einer Breite von maximal 768px */
    .navbar {
        padding: 0;
        margin-top: 50px;
    }
}

.button {
    border: none;
    cursor: pointer;
    font-size: 32px;
    line-height: 20px;
    font-weight: 600;
    margin-right: 3%;
    margin-left: 3%;
    font-family: 'Roboto', sans-serif;
    font-weight: light;

    z-index: 5;
    padding-left: 80px;
    padding-right: 50px;
}



/* Style for button hover effect */
.button:hover {

    color: #ffffff;
    border-radius: 2px;
}

.button:hover .buttonText {
    font-weight: bold;
}

.buttonText {
    transition: font-weight 0.3s ease;
    /* Add transition effect */
}

.impressum-screen {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    /* Hintergrundfarbe mit Transparenz */
    display: flex;
    justify-content: center;
    align-items: center;

}



.BZBLogo {
    width: 150px;
    height: auto;
    position: absolute;

    z-index: 8;
}

@media (max-width: 768px) {

    /* Stile für Bildschirme mit einer Breite von maximal 768px */
    .BZBLogo {
        width: 100px;


    }
}


.BZBLogoBack {
    width: 350px;
    height: auto;
    position: absolute;
    top: 0vh;

    z-index: 5;
}

@media (max-width: 768px) {

    /* Stile für Bildschirme mit einer Breite von maximal 768px */
    .BZBLogoBack {
        width: 250px;
        z-index: 5;
    }
}

.background {
    position: relative;
    width: 75%;
    height: 7500px;
    top: 20vh;
    background-color: #4D4D4D;
    border-radius: 15px;

}

@media (max-width: 768px) {

    /* Stile für Bildschirme mit einer Breite von maximal 768px */
    .background {
        width: 90%;
        height: 10300px;

    }
}

.header {
    width: 100%;
    height: auto;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #ffffff;
    font-size: 28px;
    letter-spacing: 0.1em;
    margin-top: 60px;
    text-align: left;
    margin-left: 2%;
}

@media (max-width: 768px) {

    /* Stile für Bildschirme mit einer Breite von maximal 768px */
    .header {
        font-size: 22px;

    }
}

.address {
    width: 100%;
    height: auto;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #ffffff;
    font-size: 24px;
    letter-spacing: 0.1em;
    margin-top: 40px;
    text-align: left;
    padding-left: 2%;
}

@media (max-width: 768px) {

    /* Stile für Bildschirme mit einer Breite von maximal 768px */
    .address {
        font-size: 16px;

    }
}

.text {
    width: 95%;
    height: auto;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #ffffff;
    font-size: 20px;
    letter-spacing: 0.1em;
    margin-top: 40px;
    text-align: left;
    padding-left: 2%;
    padding-right: 2%;
}

@media (max-width: 768px) {

    /* Stile für Bildschirme mit einer Breite von maximal 768px */
    .text {
        font-size: 14px;

    }
}

.text2 {
    width: 95%;
    height: auto;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #ffffff;
    font-size: 20px;
    letter-spacing: 0.1em;
    margin-top: 20px;
    text-align: left;
    margin-left: 2%;
}

@media (max-width: 768px) {

    /* Stile für Bildschirme mit einer Breite von maximal 768px */
    .text2 {
        font-size: 14px;

    }
}

.mail {
    width: 100%;
    height: auto;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #ffffff;
    font-size: 24px;
    letter-spacing: 0.1em;
    margin-top: 10px;
    text-align: left;
    margin-left: 2%;
}

@media (max-width: 768px) {

    /* Stile für Bildschirme mit einer Breite von maximal 768px */
    .mail {
        font-size: 16px;

    }
}

.back {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 42px;
    letter-spacing: 0.1em;
    transition: background-color 0.3s ease;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #000000;
    margin-top: 60px;
}

.mt110 {
    margin-top: 110px;
}

.mt60 {
    margin-top: 60px;
}

.mt20 {
    margin-top: 20px;
}
</style>